import { HomeBanner } from "./components";
import { CommonCard } from "components/common";
import ImportDocs from "assets/import-docs.svg";
import FormImage from "assets/form.svg";
import WidgetCustomisation from "assets/widget-customisation.svg";
import AssistantCustomziation from "assets/assistant-customziation.svg";
import ShareLink from "assets/share-link.svg";
import SettingsImage from "assets/settings.svg";
import CodeImage from "assets/code.svg";
import FineTuning from "assets/fine-tuning.svg";
import {
  BuiltInFeaturesSection,
  SupportVolumnSection,
  UnlimitedUseCasesSection,
} from "sections";
import ReviewsSection from "sections/ReviewsSection";
import OurLatestResultsSection from "sections/OurLatestResultsSection";

function Home() {
  return (
    <>
      <HomeBanner />
      <div className="container items-center justify-center mx-auto m-8 mt-20">
        <h5 className="w-full text-center text-2xl md:text-5xl font-bold p-4 mb-10">
          It works in 3 Simple Steps
        </h5>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 p-5 md:p-0">
          <CommonCard
            count={1}
            className="min-h-[auto] md:min-h-[275px]"
            position={"relative"}
            bgColor="#f3f4f6"
            title="Train the Al Agent with Business Data"
            description="Use your website url or upload files, or drop in raw text content to automatically train your AI Agent. "
          >
          <div className="arrow arrow-top hidden md:block"></div> 
          </CommonCard>
          <CommonCard
           className="min-h-[auto] md:min-h-[275px]"
            count={2}
            position={"relative"}
            bgColor="#f3f4f6"
            title="Deploy the Al Agent on the Business Website"
            description="Use the Embed code to deploy the AI Agent on your website and the AI Agent instantly goes live."
          />
          <CommonCard
            count={3}
            className="min-h-[auto] md:min-h-[275px]"
            position={"relative"}
            bgColor="#f3f4f6"
            title="The Al Agent Handles Customer Queries"
            description="The AI Agents can provide 24x7 Customer Support, Capture Leads, Book Appointment etc. Everything on auto pilot."
          >
            <div className="arrow arrow-bottom hidden md:block"></div>
          </CommonCard>
        </div>
      </div>
      <BuiltInFeaturesSection />
      <div className="container items-center justify-center mx-auto p-5 md:p-8">
        <h5 className="w-full text-center text-2xl md:text-5xl font-bold px-4 py-2">
          and much more...
        </h5>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <CommonCard
            imageSrc={ImportDocs}
            shape="square"
            title="Import Docs/PDF"
            description="Easily integrate your existing data and knowledge base into the Chatbot's Knowledge Hub for seamless customization."
          />
          <CommonCard
            imageSrc={FormImage}
            shape="square"
            title="Contact Form"
            description="Empower customers to connect directly with your inbox through the integrated functionality of the chatbot's Contact Form."
          />
          <CommonCard
            imageSrc={WidgetCustomisation}
            shape="square"
            title="Widget Customization"
            description="Tailor the chatbot's appearance, from placement to colors, width, and shadow, ensuring it seamlessly aligns with your website's design."
          />
          <CommonCard
            imageSrc={AssistantCustomziation}
            shape="square"
            title="Assistant Customziation"
            description="Infuse personality into the AI assistant by customizing its name and traits, fostering unique user interactions and brand consistency."
          />
          <CommonCard
            imageSrc={ShareLink}
            shape="square"
            title="Share
Ai Agents"
            description="Facilitate user interaction by sharing a direct link to your chatbot, eliminating the need for embedding it on a webpage."
          />
          <CommonCard
            imageSrc={SettingsImage}
            shape="square"
            title="Easy Integration"
            description="Effortlessly embed one or more PrimeCX chatbot widgets onto your website with swift integration."
          />

          <CommonCard
            imageSrc={CodeImage}
            shape="square"
            title="No Code Builder"
            description="Effortlessly create AI Agents/chatbots without code using our intuitive No Code Builder."
          />
          <CommonCard
            imageSrc={FineTuning}
            shape="square"
            title="Fine Tuning"
            description="Fine tune chatbot responses ro ensure only accurate information is shared with customers"
          />
        </div>
      </div>
      <UnlimitedUseCasesSection />
      <SupportVolumnSection />
      <OurLatestResultsSection />
      <ReviewsSection />
    </>
  );
}
export default Home;
