import { toBold } from "utils/utils";
import ChatGPTImpact1 from "../../assets/blogs/chatgpt-impact1.png";
import ChatGPTImpact2 from "../../assets/blogs/chatgpt-impact2.jpg";
import { useLocation } from "react-router-dom";
import { BlogComponent } from "./BlogComponent";

export function ChatGPTImpact() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const html: string = `
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      In recent times, the buzz around ChatGPT has been hard to ignore. This
      unassuming chatbot has skyrocketed to 100 million active users in just two
      months, outpacing the growth of social media giants like TikTok and
      Instagram. While ChatGPT is making waves in various industries, the
      question lingers: Is it poised to snatch away our customer service jobs?
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      If you're curious about the implications, keep reading for insights, or
      dive into our on-demand webinar for a comprehensive exploration!
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **Unlocking the Power of Generative AI: A Historical Perspective**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      The fascination with artificial intelligence (AI) dates back to the early
      20th century, fueled by cinematic depictions and literary works. The
      groundwork for AI was laid in the 1950s, with Alan Turing's pivotal Turing
      Test providing a logical framework that still defines AI today. Coined in
      1956, the term "artificial intelligence" marked the birth of a field that
      was, at the time, constrained by limited computing power.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Fast forward to the present, and ChatGPT stands out as a game-changer.
      Unlike its predecessors, it has democratized AI, offering a conversational
      interface that empowers users without the need for coding or mathematical
      expertise.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **ChatGPT in the Limelight: Making AI Accessible**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      The buzz around ChatGPT isn't without reason. Its popularity stems from
      its accessibility, making AI available to everyone. In a recent webinar,
      70% of participants admitted to using ChatGPT in their professional
      capacities, signaling its widespread adoption. The challenge now is how to
      leverage this tool effectively.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **Strategic Applications of ChatGPT in Customer Service**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      1. **Insights from Interactions:** Utilize ChatGPT to analyze customer
      interactions, gaining valuable insights into behavior and preferences.
      Armed with this knowledge, tailor responses to enhance overall customer
      experience and foster loyalty.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      2. **Handling Repetitive Inquiries:** Streamline customer service by
      automating responses to repetitive inquiries, freeing up agents for more
      complex tasks. Features like Smart Conversation Summaries ensure quick
      access to past interactions, saving time and improving efficiency.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      3. **Boosting Team Productivity:** Enhance your team's productivity with
      ChatGPT, enabling them to handle more tasks without compromising quality.
      Features like Smart Conversation Replies leverage ChatGPT to draft
      responses, saving time and ensuring personalized service at scale.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      4. **Staying Ahead of the Curve:** Embrace emerging technology like
      ChatGPT to stay ahead in the evolving landscape of customer service. By
      developing skills in tandem with innovative tools, you position yourself
      for future success and career advancement.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      5. **ChatGPT as an Assistant, Not a Replacement:** Dispel the myth –
      ChatGPT is not here to replace human agents but to assist them. By
      collaborating with ChatGPT, you can improve response times and accuracy,
      ultimately leading to heightened customer satisfaction.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">**ChatGPT: A Tool to be Wielded with Care**</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      While ChatGPT is a powerful tool, it requires thoughtful and strategic
      implementation. It's a versatile Swiss army knife that enhances
      productivity but needs careful operation. Introducing ChatGPT in a
      considerate manner can improve the agent experience, removing
      time-consuming tasks and allowing them to focus on customer interactions.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **Addressing Concerns: Is ChatGPT Coming for Your Job?**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      In our ChatGPT webinar, concerns about AI were met with a varied response.
      Only 8% of attendees expressed worry, while 42% felt somewhat concerned,
      and a significant 50% were not worried at all. This nuanced perspective
      suggests a healthy mix of caution and optimism.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      While ChatGPT is undeniably a disruptor, a measured approach can make it a
      game-changer in enhancing customer service efficiency. The key is to
      embrace innovation while proceeding with caution, ensuring that ChatGPT
      becomes a valuable ally rather than a threat to customer service jobs.
    </span>
  </p>
  <p className="c2">
    <span className="c1">
      <br />
      And, just to be safe, we asked ChatGPT:
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span
      style={{
        overflow: "hidden",
        display: "inline-block",
        margin: "0.00px 0.00px",
        border: "0.00px solid #000000",
        transform: "rotate(0.00rad) translateZ(0px)",
        WebkitTransform: "rotate(0.00rad) translateZ(0px)",
        width: "400.00px",
        height: "200.33px"
      }}
    >
      <img
        alt=""
        src=${ChatGPTImpact1}
        style={{
        width: "400.00px",
        height: "200.33px",
          marginLeft: "0.00px",
          marginTop: "0.00px",
          transform: "rotate(0.00rad) translateZ(0px)",
          WebkitTransform: "rotate(0.00rad) translateZ(0px)"
        }}
        title=""
      />
    </span>
    
  </p>
  <p className="c0">
    <span className="c1" />
  </p>

`;

  const stringHtml = toBold(html);
  return (
    <div className="bg-white p-10 md:p-20">
      <div className="px-10 md:px-20">
        <h5 className="text-2xl md:text-5xl font-bold my-4">
          Navigating the Impact of ChatGPT on Customer Service Roles
        </h5>
        <div className="flex flex-row justify-center">
          <img
            alt=""
            src={ChatGPTImpact2}
            style={{
              width: "600.00px",
              height: "600.00px",
              overflow: "hidden",
              display: "inline-block",
            }}
            title=""
          />
        </div>
        <div
          className="space-y-4 mt-12 "
          dangerouslySetInnerHTML={{ __html: stringHtml }}
        ></div>
      </div>

      <div className="">
        <h1 className="w-full text-left text-3xl font-bold p-4 mt-4">
          Other Posts
        </h1>

        <BlogComponent exclude={[lastPathSegment]} />
      </div>
    </div>
  );
}
