import { Navbar, Button } from "flowbite-react";
import PrimeCXLogo from "assets/PrimeCXLogo.png";
import { useLocation } from "react-router-dom";
import { LOGIN_URL, REGISTER_URL } from "common/constants";

export const Header = () => {
  const location = useLocation(); // Get the current location

  const isActive = (path: string): boolean => {
    return location.pathname === path;
  };
  return (
    <Navbar
      rounded
      className="bg-primary-100 border-b-2 sticky top-0 z-50 w-full"
    >
      <Navbar.Brand href="/">
        <img src={PrimeCXLogo} className="mr-3 h-6 sm:h-9" alt="PrimeCX Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Button
          color="secondary"
          className="mr-5 rounded-full"
          onClick={() => {
            window.location.href = REGISTER_URL;
          }}
        >
          Sign Up
        </Button>
        <Button
          color="primary"
          className="rounded-full"
          onClick={() => {
            window.location.href = LOGIN_URL;
          }}
        >
          Sign In
        </Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link
          href="/"
          active={isActive("/")}
          className={`hover:text-primary-300 ${
            isActive("/") ? "text-primary-500" : "text-gray-800"
          }`}
        >
          Home
        </Navbar.Link>
        <Navbar.Link
          href="/pricing"
          active={isActive("/pricing")}
          className={`hover:text-primary-300 ${
            isActive("/pricing") ? "text-primary-500" : "text-gray-800"
          }`}
        >
          Pricing
        </Navbar.Link>
        <Navbar.Link
          href="/features"
          active={isActive("/features")}
          className={`hover:text-primary-300 ${
            isActive("/features") ? "text-primary-500" : "text-gray-800"
          }`}
        >
          Features
        </Navbar.Link>
        <Navbar.Link
          href="/faq"
          active={isActive("/faq")}
          className={`hover:text-primary-300 ${
            isActive("/faq") ? "text-primary-500" : "text-gray-800"
          }`}
        >
          FAQ
        </Navbar.Link>
        <Navbar.Link
          href="/blog"
          active={isActive("/blog")}
          className={`hover:text-primary-300 ${
            isActive("/blog") ? "text-primary-500" : "text-gray-800"
          }`}
        >
          Blog
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};
