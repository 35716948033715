import { useState } from "react";
import { CommonCard } from ".";
// import { CommonCard } from "./CommonCard";
interface Item {
  title: string;
  icon: string;
}
interface Step {
  id: string;
  title: string;
  description: string;
  items: Array<Item>;
}

export interface FeatureDetailsProps {
  title: string;
  steps: Array<Step>;
}
export const FeatureDetails = ({ title, steps }: FeatureDetailsProps) => {
  const [activeStep, setActiveStep] = useState(steps[0]);
  return (
    <div className="container mx-auto mt-20">
      <h1 className="font-extrabold text-2xl text-center md:text-left">
        {title}
      </h1>
      <hr className="h-px my-8 bg-primary-300 border-0" />
      <div className="grid md:grid-cols-3 gap-4 mt-4">
        <div className="col col-span-3 md:col-span-1 ">
          <ul>
            {steps.map((step, index) => {
              return (
                <li
                  key={activeStep.id + index}
                  className={`font-bold cursor-pointer leading-loose text-center md:text-left ${
                    step.id === activeStep.id ? `text-primary-500` : ``
                  }`}
                  onClick={() => setActiveStep(step)}
                >
                  {step.title}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col col-span-2 text-center lg:text-start mt-4 md:mt-0">
          <CommonCard
            bgColor="#ECF8F4"
            height="auto"
            description={activeStep.description}
          >
            <div
              className={`mt-4 grid gap-4 grid-cols-1 md:grid-cols-${
                title === "Additional Options" ? 2 : activeStep.items.length
              }`}
            >
              {activeStep.items.map((item, index) => {
                return (
                  <div className="text-center" key={item.title + index + 'div'}>
                    <CommonCard
                      imageSrc={item.icon}
                      imageInCenter={true}
                      title={item.title}
                      className="min-h-[100%]"
                    />
                  </div>
                );
              })}
            </div>
          </CommonCard>
        </div>
      </div>
    </div>
  );
};
