import { Card } from "flowbite-react";
import React, { useRef } from "react";
import Quote from "assets/quote.svg";

// Define a type for individual review
type Review = {
  text: string;
  author: string;
  designation: string;
};

// If you have props, define a type for them as well
// type ReviewsSectionProps = {
//   // Define your props here
// };

const reviews: Review[] = [
  {
    text: "We have been using PrimeCX to help streamline and reduce our customer service costs. It was very easy to implement, even easier to train and is customised specifically to our brand. PrimeCx is the only customer service chatbot using ChatGPT that you will ever need",
    author: "Geoff",
    designation: "The Open Pantry",
  },
  {
    text: "Simple and easy to use. Super effective and have helped us reduce our repeat customer queries by 80%. Highly recommend it!",
    author: "Natasha",
    designation: "CMO - Moninyze",
  },
  {
    text: "So far I love the experience. Helped us book a lot of qualified demos and we were able to offer 24x7 customer support to our customers by using the AI Agents trained on our company data.",
    author: "Jordan",
    designation: "Director Sales - Shopify Agency",
  },
];

const ReviewsSection: React.FC /* <ReviewsSectionProps> */ = (/* props */) => {
  const scrollRef = useRef<HTMLUListElement>(null);

  const scroll = (scrollOffset: number): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="container mx-auto rounded-lg">
    <div className="items-center justify-center mx-auto p-5 md:p-8">
      <h1 className="text-2xl md:text-3xl font-bold py-4">
        What our Client say about us
      </h1>
      <div className="flex items-center">
        {/* Scroll buttons can be added here */}
        <ul ref={scrollRef} className="flex overflow-x-scroll no-scrollbar p-1">
          {reviews.map((review, index) => (
            <li key={index} className="flex-none mr-2">
              <Card className="flex flex-col justify-between bg-blue-100 w-80 md:w-96 h-80">
                <div className="flex-grow">
                  <img
                    src={Quote}
                    alt="quote"
                    style={{ width: "43px", height: "33px" }}
                    className="mb-4"
                  />
                  <p
                    className="text-gray-600 text-sm font-bold overflow-hidden"
                    style={{
                      WebkitLineClamp: 6,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {review.text}
                  </p>
                </div>
                <div className="pt-4">
                  <hr className="border-t border-black mb-2" />
                  <p className="text-gray-900 text-sm font-bold">
                    {review.author}
                  </p>
                  <p className="text-gray-500 text-xs">{review.designation}</p>
                </div>
              </Card>
            </li>
          ))}
        </ul>
        {/* Scroll buttons can be added here */}
      </div>
    </div>
    </div>
  );
};

export default ReviewsSection;
