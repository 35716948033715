import { toBold } from "utils/utils";
import EssentialInsightsIcon from "../../assets/blogs/essential-insights.jpg";
import { useLocation } from "react-router-dom";
import { BlogComponent } from "./BlogComponent";
export function EssentialInsights() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const html: string = `
    
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          As the importance of delivering an exceptional customer experience
          continues to rise, businesses are placing strategic emphasis on
          customer service. A recent survey by McKinsey &amp; Company
          highlighted the increasing recognition of customer service as a
          crucial focus for companies. However, aligning organizational
          priorities with customer expectations can be a nuanced challenge.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          While organizations often prioritize metrics like resolution time,
          customers are more concerned with the quality of service they receive.
          This underscores the need for businesses to understand and measure
          agent performance effectively. Deloitte's survey revealed a
          misalignment between commonly measured performance areas and customer
          satisfaction priorities.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          The survey identified key areas frequently used to measure agent and
          team performance, including:
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          1. Quality of service/contact/fulfillment (73%)
        </span>
      </p>
      <p className="c2">
        <span className="c1">2. Time taken to resolve an issue (51%)</span>
      </p>
      <p className="c2">
        <span className="c1">
          3. Number of issues solved in a given period (44%)
        </span>
      </p>
      <p className="c2">
        <span className="c1">4. First contact resolution (FCR) (40%)</span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          These metrics play a pivotal role in evaluating the effectiveness of
          agents and the overall team. To enhance the customer experience,
          organizations must analyze and optimize these indicators, aligning
          them with business objectives and fostering desired customer-centric
          behaviors.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          Let's delve into 10 critical agent performance metrics that businesses
          should prioritize:
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**1. First Response Time (FRT):**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Measure the time taken for a customer to receive the
          initial response after submitting a query.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Shorter response times contribute to customer
          satisfaction and prevent frustration.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**2. First Contact Resolution Rate (FCR):**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Evaluate the percentage of inquiries resolved during
          the initial contact, indicating agent efficiency.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- A higher FCR rate correlates with improved performance
          and increased customer satisfaction.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**3. Rate of Answered Calls:**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Assess the proportion of incoming calls answered by
          agents, reflecting effective call management.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Utilize call routing technologies and script
          evaluations to enhance call response rates.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**4. Average Handling Time (AHT):**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Measure the average time taken to handle a customer
          interaction.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Emphasize agent product/service knowledge and leverage
          knowledge bases for efficient issue resolution.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**5. After-Interaction Work (AIW):**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Quantify the time and effort spent on post-interaction
          tasks.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Encourage comprehensive note-taking during interactions
          to reduce post-interaction documentation needs.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**6. Overall Resolution Rate:**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Gauge the effectiveness of agents in resolving customer
          issues.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Promote active listening to enhance agents' ability to
          address customer needs comprehensively.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**7. Agent Adherence to Schedule:**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Evaluate how well agents adhere to scheduled working
          hours, indicating punctuality and availability.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Gain insights into agent compliance with assigned work
          schedules.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**8. Quality Assurance Score (QA):**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Assess agent adherence to company guidelines during
          customer interactions.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Track factors such as tone, politeness, and product
          knowledge to ensure high-quality service.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**9. Agent Churn:**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Measure the rate at which agents leave or discontinue
          employment.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- A higher churn rate indicates increased turnover,
          highlighting potential challenges in workforce stability.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">**10. Customer Satisfaction Score (CSAT):**</span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- Measure customer satisfaction through surveys or
          feedback forms.
        </span>
      </p>
      <p className="c2">
        <span className="c1">
          &nbsp; &nbsp;- A high CSAT rate indicates customer contentment, while
          a low rate signals areas for improvement.
        </span>
      </p>
      <p className="c0">
        <span className="c1" />
      </p>
      <p className="c2">
        <span className="c1">
          In conclusion, while agent performance metrics are essential for
          organizational success, they also empower agents by showcasing the
          value they bring. By focusing on metrics such as response time, FCR,
          overall resolution rate, and CSAT, organizations can enhance customer
          satisfaction, loyalty, and the overall customer experience. Regular
          analysis of these metrics enables businesses to identify improvement
          areas, align performance with customer expectations, and make
          informed, data-driven decisions to elevate customer service
          excellence.
        </span>
      </p>
    `;

  const stringHtml = toBold(html);
  return (
    <div className="bg-white p-14">
      <div className="px-0 md:px-20">
        <h5 className="text-2xl md:text-5xl font-bold my-4">
          Embracing Efficiency: 3 Compelling Reasons Your Customer Service Team
          Will Embrace Chatbots
        </h5>
        <div className="flex flex-row justify-center">
          <img
            className="w-96 md:w-1/3 h-auto"
            alt=""
            src={EssentialInsightsIcon}
            style={{
              overflow: "hidden",
              display: "inline-block",
            }}
            title=""
          />
        </div>
        <div
          className="space-y-4 mt-12 "
          dangerouslySetInnerHTML={{ __html: stringHtml }}
        ></div>
      </div>

      <div className="">
        <h1 className="w-full text-left text-3xl font-bold p-4 mt-4">
          Other Posts
        </h1>

        <BlogComponent exclude={[lastPathSegment]} />
      </div>
    </div>
  );
}
