import { Card, Button } from "flowbite-react";
import DoubleTickPrimary from "assets/double-tick-primary.svg";
import DoubleTickWhite from "assets/double-tick-white.svg";
import { REGISTER_URL } from "common/constants";
interface PricingCardProps {
  activeInterval: string;
  bgColor?: string;
  textColor?: string;
  title: string;
  description: string;
  price: string;
  annualPrice: string;
  features: string[];
  ctaText?: string;
  ctaLink?: string;
  ctaBgColor?: string;
  ctaTextColor?: string;
}
export const PricingCard = ({
  activeInterval = "monthly",
  bgColor = "bg-white",
  textColor,
  title = "Plan",
  description = "best",
  price = "$0.00",
  annualPrice = "$0.00",
  features = [],
  ctaText = "Start Free Trial",
  ctaLink = "/",
  ctaBgColor = "bg-white",
  ctaTextColor = "text-black",
}: PricingCardProps) => {
  return (
    <Card className={`rounded-lg pricing-card ${bgColor} ${textColor}`}>
    
      <h1 className={`text-4xl font-extrabold`}>{title}</h1>
      <p className="text-sm">{description}</p>
      <h1
        className={`text-4xl font-extrabold ${
          !textColor ? `text-primary-500` : ``
        }`}
      >
        {activeInterval === "monthly" ? price : annualPrice}
        &nbsp;
        <span
          className={`text-sm font-thin ${!textColor ? `text-slate-500` : ``}`}
        >
          {activeInterval === "monthly" ? "/Month" : "/Year"}
        </span>
      </h1>
      <hr className="bg-slate-500 h-px" />
      <ul className="min-h-64">
        {features.map((feature, index) => (
          <li key={feature+index} className="text-sm leading-loose flex">
            <img src={!textColor ? DoubleTickPrimary : DoubleTickWhite} />
            &nbsp;
            {feature}
          </li>
        ))}
      </ul>
      <Button
        className={`rounded-full w-full ${ctaBgColor} ${ctaTextColor}`}
        onClick={() => {
          window.location.href = REGISTER_URL;
        }}
      >
        {ctaText}
      </Button>

    </Card>
  );
};
