// import { Circle, RoundedSquare } from "components/common";
import { Circle } from "./Circle";
import { RoundedSquare } from "./RoundedSquare";

interface Padding {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

export interface ImageViewConfig {
  src?: string;
  rotation?: number; // Rotation in degrees
  marginStart?: string; // Margin start value, e.g., 'ms-4'
  margintTop?: string;
  marginEnd?: string;
  startFromRight?: boolean;
}

export interface CommonCardProps {
  count?: number;
  imageSrc?: string;
  imageInCenter?: boolean;
  title?: React.ReactNode | string;
  description?: React.ReactNode | string;
  shape?: "circle" | "square" | "none";
  bgColor?: string;
  height?: string;
  imageViewConfig?: ImageViewConfig;
  children?: React.ReactNode;
  padding?: Padding;
  position?: any;
  minWidth?: string;
  className?: string;
}

export const CommonCard = ({
  count,
  imageSrc,
  imageInCenter = false,
  title,
  description,
  shape = "circle",
  bgColor = "white",
  height,
  imageViewConfig,
  padding,
  children,
  position,
  minWidth,
  className
}: CommonCardProps) => {
  const ShapeComponent = shape === "circle" ? Circle : RoundedSquare;


  const baseClass = "flex flex-col border border-gray-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"; // Default classes
  const combinedClass = `${baseClass} ${className || ''}`;

  const renderImageView = () => {
    if (imageViewConfig && imageViewConfig.src) {
      return (
        <img
          src={imageViewConfig.src}
          alt="dynamic content"
          style={{
            display: "block",
            marginLeft: imageViewConfig.startFromRight ? "auto" : "auto",
            marginRight: imageViewConfig.startFromRight
              ? imageViewConfig.marginEnd ?? "0px"
              : "auto",
            marginTop: imageViewConfig.margintTop,
          }}
          className="object-cover"
        />
      );
    }
    return null;
  };

  return (
    <div
      className={combinedClass}
      style={{
        // height: height ? height : "unset",
        backgroundColor: bgColor,
        paddingLeft: padding?.left ?? "24px",
        paddingRight: padding?.right ?? "24px",
        paddingTop: padding?.top ?? "24px",
        paddingBottom: padding?.bottom ?? "24px",
        position: position ?? 'unset',
        gap: "20px",
        justifyContent: imageViewConfig && imageViewConfig.src ? 'space-between' : "flex-start",
        minWidth:  minWidth ? minWidth : "unset"
      }}
    >
      {(count || imageSrc) &&
        (shape !== "none" ? (
          <ShapeComponent
            classes={`${
              imageInCenter ? `mx-auto ` : ``
            }relative w-10 h-10 text-white text-center flex items-center justify-center bg-gradient-to-r from-primary-300 via-primary-400 to-primary-500`}
          >
            {imageSrc ? (
              <img
                src={imageSrc}
                alt="content"
                className="w-5 h-5 object-cover"
              />
            ) : (
              <h5 className="text-xl font-bold">{count}</h5>
            )}
          </ShapeComponent>
        ) : (
          imageSrc && (
            <img
              src={imageSrc}
              alt="content"
              className="w-12 h-12 object-cover"
            />
          )
        ))}
        <div className="wrap-title-desc" style={{
          gap: "10px",
          display: "flex",
          flexDirection :"column"
        }} >
        {title && (
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        )}
        {description && (
          <p className="text-sm font-normal text-gray-700 dark:text-gray-400">
            {description}
          </p>
        )}
      </div>
      {renderImageView()}
      {children &&<>{children}</>}
    </div>
  );
};
