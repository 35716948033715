import { Card } from "flowbite-react";
import { CommonCardProps } from "components/common";

export const CardWithImageGradient = ({
  imageSrc,
  title,
  description,
  bgColor = "white",
  height,
}: CommonCardProps) => {
  return (
    <div style={{
      display: "flex",
      height: "100%"
    }}>

      
    <div data-testid="flowbite-card" className="flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col justify-content-left relative"
      style={{
        // height: "100%",
        backgroundColor: bgColor,
        padding: "24px 24px 0px"
      }}
      
    >
       <div
        style={{
          display: "flex", // Apply flexbox layout
          justifyContent: "center", // Center horizontally
          position: "relative", // Position the container relatively
          width: "100%",
          // height: "70%",
        }}
      >
      <img src={imageSrc} alt="content" style={{margin: "auto", width: "60%", objectFit: "contain"}}></img>
      <div
          style={{
            position: "absolute", // Position the gradient overlay
            bottom: 0,
            left: 0,
            right: 0, // Stretch across the entire width
            height: "20%", // Height of the gradient
            backgroundImage: `linear-gradient(transparent, ${bgColor})`, // Gradient from transparent to bgColor
          }}
        ></div>
      </div>
       
       
     
      <div className="flex h-full flex-col gap-4 p-6">
      {title && (
        <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
      )}
      {description && (
        <p className="text-sm font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
      )}
      </div>
    </div>
    </div>
  );
};
