import { FeaturesBanner, FeatureDetails } from "./components";
import ImportDocs from "assets/import-docs.svg";
import CollectData from "assets/features/collect-data.svg";
import CaptureProduct from "assets/features/capture-product.svg";
import Volunteer from "assets/features/volunteer.svg";
import OptimiseLead from "assets/features/optimise-lead.svg";
import PromptUser from "assets/features/prompt-user.svg";
import Schedule from "assets/features/schedule.svg";
import CaptureLead from "assets/features/capture-lead.svg";
import GatherFeedback from "assets/features/gather-feedback.svg";
import CampusTours from "assets/features/campus-tours.svg";
import InstantInformation from "assets/features/instant-information.svg";
import AddressFaq from "assets/features/address-faq.svg";
import ShareDetails from "assets/features/share-details.svg";
import ArrangeFaculty from "assets/features/arrange-faculty.svg";
import TransferQueries from "assets/features/transfer-queries.svg";
import HandoffAdmission from "assets/features/handoff-admission.svg";
import Redirect from "assets/features/redirect.svg";
import MaintainBrand from "assets/features/maintain-brand.svg";
import PersonaliseChat from "assets/features/personalise-chat.svg";
import NewPolicies from "assets/features/new-policies.svg";
import ExcludeOutdated from "assets/features/exclude-outdated.svg";
import AddressQueries from "assets/features/address-queries.svg";
import RespondQueries from "assets/features/respond-queries.svg";
import QuickResponse from "assets/features/quick-response.svg";
import CorrectInformation from "assets/features/correct-information.svg";
import EnhaceResponses from "assets/features/enhance-responses.svg";
import AnalyseInteraction from "assets/features/analyse-interaction.svg";
import ManageSubscription from "assets/features/manage-subscription.svg";
import MonitorUsage from "assets/features/monitor-usage.svg";
import AdjustPlans from "assets/features/adjust-plans.svg";
import TrackGrowth from "assets/features/track-growth.svg";
import ManageBilling from "assets/features/manage-billing.svg";
import UpdateDetails from "assets/features/update-details.svg";
import Collaborate from "assets/features/collaborate.svg";
import Integration from "assets/features/integration.svg";
import Settings from "assets/features/settings.svg";
import DirectUser from "assets/features/direct-user.svg";
import Edit from "assets/features/edit.svg";
import TailorTone from "assets/features/tailor-tone.svg";
import SimplifyIntegration from "assets/features/simplify-integration.svg";
import NoCoding from "assets/features/no-coding.svg";
import MultipleLanguages from "assets/features/multiple-languages.svg";

function Features() {
  return (
    <>
      <FeaturesBanner />
      <FeatureDetails
        title="Lead Generation"
        steps={[
          {
            id: "lg-1",
            title: "Form Creation",
            description:
              "Create customizable lead generation forms with dynamic fields. - Enable/Disable form fields for flexibility. - Set form element order with sort options. - Manage lead forms efficiently with add/edit/delete options. - Map forms to specific chatbots for targeted interactions.",
            items: [
              {
                icon: CollectData,
                title: "Collect prospect data for admissions.",
              },
              {
                icon: CaptureProduct,
                title: "Capture diverse product inquiries for businesses.",
              },
              {
                icon: Volunteer,
                title: "Facilitate volunteer sign-ups for non-profits.",
              },
            ],
          },
          {
            id: "lg-2",
            title: "Lead Generation Setting",
            description:
              "Toggle lead generation On/Off. - Choose between pre and post-chat form appearances for varied user experiences.",
            items: [
              {
                icon: OptimiseLead,
                title: "Optimize lead capture for webinar sign-ups.",
              },
              {
                icon: PromptUser,
                title: "Prompt users for feedback after chat interactions.",
              },
              {
                icon: Schedule,
                title: "Schedule consultations seamlessly.",
              },
            ],
          },
          {
            id: "lg-3",
            title: "Lead Form Display in Chatbot",
            description:
              "Dynamically display lead forms based on settings. - Trigger lead forms after idle chat to re-engage users.",
            items: [
              {
                icon: CaptureLead,
                title: "Capture leads during marketing webinars.",
              },
              {
                icon: GatherFeedback,
                title: "Gather feedback with post-chat forms.",
              },
              {
                icon: CampusTours,
                title: "Schedule campus tours for prospective students.",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Question/Answer (QA)"
        steps={[
          {
            id: "qa-1",
            title: "QA Module",
            description:
              "Configure pre-set responses for common questions. Add new questions and responses dynamically. Consider CSV upload for efficient QA management.",
            items: [
              {
                icon: InstantInformation,
                title: "Provide instant information about courses.",
              },
              {
                icon: AddressFaq,
                title: "Address FAQs on admission criteria.",
              },
              {
                icon: ShareDetails,
                title: "Share details about upcoming events",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Calendar Booking"
        steps={[
          {
            id: "cb-1",
            title: "Booking Appointments",
            description:
              "Specify text for appointment basis. - Integrate Google Calendar for streamlined availability.",
            items: [
              {
                icon: Schedule,
                title: "Schedule counseling sessions effortlessly.",
              },
              {
                icon: CampusTours,
                title: "Book campus tours with available slots.",
              },
              {
                icon: ArrangeFaculty,
                title: "Arrange faculty consultations seamlessly.",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Agent Handoff"
        steps={[
          {
            id: "ah-1",
            title: "Agent Handoff Setting",
            description:
              "Enable/Disable agent handoff. - Select handoff channels (Facebook, WhatsApp, Email, etc.). Customize email form for seamless handoff. Provide public URL of chat at the time of handoff for reference.",
            items: [
              {
                icon: TransferQueries,
                title: "Transfer complex queries to live agents.",
              },
              {
                icon: HandoffAdmission,
                title: "Hand off admissions related questions.",
              },
              {
                icon: Redirect,
                title: "Redirect technical inquiries to experts.",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Chatbot Customization"
        steps={[
          {
            id: "cc-1",
            title: "Customization Features",
            description:
              "Add company details for a personalized touch. - Customize chatbot colors, logo, and picture. - Set welcome messages and guidelines for a unique user experience.",
            items: [
              {
                icon: MaintainBrand,
                title: "Maintain brand consistency in chat.",
              },
              {
                icon: CaptureProduct,
                title: "Tailor chatbots for different departments.",
              },
              {
                icon: PersonaliseChat,
                title: "Personalize chat experiences for users.",
              },
            ],
          },
          {
            id: "cc-2",
            title: "Data Upload for Training",
            description:
              "Upload files up to 100MB (PDFs, DOCs, CSVs, website URLs). - Exclude specific URLs for focused training.",
            items: [
              {
                icon: CaptureProduct,
                title: "Train chatbots with updated course catalogs",
              },
              {
                icon: NewPolicies,
                title: "Incorporate new policies into chatbot's knowledge",
              },
              {
                icon: ExcludeOutdated,
                title: "Exclude outdated information for accuracy",
              },
            ],
          },
          {
            id: "cc-3",
            title: "Trigger Words/Phrases",
            description:
              "Train bot for common interactions. - Configure responses for specific triggers.",
            items: [
              {
                icon: AddressQueries,
                title: "Address common admission queries.",
              },
              {
                icon: RespondQueries,
                title: "Respond to queries about campus facilities.",
              },
              {
                icon: QuickResponse,
                title: "Provide quick responses during orientation.",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Review & Analytics"
        steps={[
          {
            id: "ra-1",
            title: "Fine Tuning & Analytics",
            description:
              "Manually fix inaccurate responses. - Review chat logs for continuous improvement. - Export chat data for further analysis.",
            items: [
              {
                icon: CorrectInformation,
                title: "Correct course-related misinformation.",
              },
              {
                icon: EnhaceResponses,
                title: "Enhance chatbot responses for specific queries.",
              },
              {
                icon: AnalyseInteraction,
                title: "Analyze user interactions for continuous improvement.",
              },
            ],
          },
          {
            id: "ra-2",
            title: "Subscription Management",
            description:
              "Display available plans and features for user selection. - Monitor chatbot usage for billing accuracy. - Adjust plans based on evolving needs.",
            items: [
              {
                icon: ManageSubscription,
                title: "Manage subscription upgrades for increased usage.",
              },
              {
                icon: MonitorUsage,
                title: "Monitor chatbot usage for billing accuracy.",
              },
              {
                icon: AdjustPlans,
                title: "Adjust plans based on evolving needs.",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Agency Management"
        steps={[
          {
            id: "am-1",
            title: "Agency Dashboard",
            description:
              "Overview of clients, MRR, and total bots on the dashboard. - Update agency details for accurate branding. - Manage billing and subscriptions seamlessly.",
            items: [
              {
                icon: TrackGrowth,
                title: "Track agency growth with client statistics.",
              },
              {
                icon: ManageBilling,
                title: "Manage billing and subscriptions seamlessly.",
              },
              {
                icon: UpdateDetails,
                title: "Update agency details for accurate branding.",
              },
            ],
          },
          {
            id: "am-2",
            title: "User Invitations & API",
            description:
              "Invite users to manage the agency account. - Add own ChatGPT API for advanced integrations.",
            items: [
              {
                icon: Collaborate,
                title: "Collaborate with multiple team members.",
              },
              {
                icon: Integration,
                title: "Seamlessly integrate with existing systems.",
              },
              {
                icon: Settings,
                title: "Streamline API access for advanced users.",
              },
            ],
          },
        ]}
      />
      <FeatureDetails
        title="Additional Options"
        steps={[
          {
            id: "ao-1",
            title: "Import Docs/PDF/XLS etc",
            description:
              "Enable the import of documents in various formats (PDF, DOC, XLS, etc.).",
            items: [
              {
                icon: PersonaliseChat,
                title: "Train chatbots with diverse document types",
              },
            ],
          },
          {
            id: "ao-2",
            title: "Contact Form",
            description:
              "Include editable contact forms within the chatbot for user inquiries",
            items: [
              {
                icon: DirectUser,
                title: "Facilitate direct user interaction via contact forms.",
              },
            ],
          },
          {
            id: "ao-3",
            title: "Widget Customization",
            description:
              "Allow users to modify the chat widget's appearance, including colors, logos, and positioning.",
            items: [
              {
                icon: Edit,
                title: "Customize the appearance and behavior of chat widgets.",
              },
            ],
          },
          {
            id: "ao-4",
            title: "Assistant Customization",
            description:
              "Provide options to customize the chatbot's responses, tone, and personality for brand alignment",
            items: [
              {
                icon: TailorTone,
                title: "Tailor the assistant's personality and tone",
              },
            ],
          },
          {
            id: "ao-5",
            title: "Share AI Agents",
            description:
              "Enable sharing of pre-configured AI agents between users or agencies for collaborative efforts.",
            items: [
              {
                icon: Settings,
                title: "Collaborate and share configured AI agents",
              },
            ],
          },
          {
            id: "ao-6",
            title: "Easy Integration",
            description:
              "Facilitate easy integration with common platforms, ensuring seamless connectivity.",
            items: [
              {
                icon: SimplifyIntegration,
                title: "Simplify the integration process with other tools",
              },
            ],
          },
          {
            id: "ao-7",
            title: "No Code Builder",
            description:
              "Provide a user-friendly, no-code builder for chatbot creation and customization.",
            items: [
              {
                icon: NoCoding,
                title: "Develop chatbots without coding skills",
              },
            ],
          },
          {
            id: "ao-8",
            title: "Fine Tuning",
            description:
              "Offer fine-tuning options to enhance the chatbot's understanding and responses over time",
            items: [
              {
                icon: TrackGrowth,
                title: "Refine chatbot responses for better accuracy",
              },
            ],
          },
          {
            id: "ao-9",
            title: "Multiple Languages Support",
            description:
              "Enable chatbots to communicate in multiple languages, catering to a diverse user base.",
            items: [
              {
                icon: MultipleLanguages,
                title: "Serve users in their preferred languages",
              },
            ],
          },
        ]}
      />
    </>
  );
}
export default Features;
