import { AgencyPricingBanner } from "components/pricing/AgencyPricingBanner";
import ReviewsSection from "sections/ReviewsSection";

export function AgencyPricing() {
  return (
    <>
      <AgencyPricingBanner />
      <ReviewsSection />
    </>
  );
}
