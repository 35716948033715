import { BlogItem } from "type";

const faqData: BlogItem[] = [
  {
    title: "Scope",
    description: `<p>This Refund Policy applies to all products and services offered by PrimeCX.ai.</p></br>`,
  },
  {
    title: "No Refund Policy",
    description: `<p>We regret to inform you that we do not offer refunds for any of our products or services. Once a purchase is made, it is considered final. We do not provide refunds or exchanges for change of mind or dissatisfaction with our products or services.</p></br>`,
  },
  {
    title: "Exceptional Circumstances",
    description: `<p>In exceptional circumstances, such as errors made on our part, billing discrepancies, or technical issues with our products or services, we may consider providing a refund at our sole discretion. Any such requests must be submitted in writing to our customer support team at support@primecx.ai within 7 days of the purchase date. We reserve the right to determine, in our sole discretion, whether a refund is warranted.</p></br>`,
  },
  {
    title: "Contact Us",
    description: `<p>If you have any questions or concerns about our Refund Policy, please contact us at support@primecx.ai</p></br>`,
  },
  {
    title: "Amendments",
    description: `<p>We reserve the right to modify or amend this Refund Policy at any time. Any changes will be effective immediately upon posting on this page. It is your responsibility to review this Refund Policy periodically for any updates.</p></br>
    <p>By using our website and purchasing our products or services, you acknowledge that you have read, understood, and agree to be bound by this Refund Policy.</p></br>
    <p>Thank you for your understanding.</p></br>`,
  },
];
export function RefundPolicy() {
  return (
    <div className="bg-white p-14">
      <h5 className="text-2xl md:text-5xl font-bold my-4">Refund Policy</h5>
      <p className="text-gray-400 text-sm mb-4">
        Thank you for choosing PrimeCX.ai.
      </p>
      <p className="text-gray-400 text-sm mb-4">
        This Refund Policy governs the manner in which PrimeCX.ai ("we", "our",
        or "us") handles refunds for products or services purchased through our
        website.
      </p>
      <div className="space-y-4 mt-8 ">
        {faqData.map((blog, index) => (
          <div>
            <h5 className="text-xl md:text-2xl font-bold my-4">{blog.title}</h5>
            <div
              className="mt-2 text-gray-400 text-sm"
              dangerouslySetInnerHTML={{ __html: blog.description }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
