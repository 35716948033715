import { Container } from "components";
import MeteorShower from "assets/meteor-shower.svg";
import HomeBannerGraphic from "assets/home-banner-graphic.svg";
import Robotichand from "assets/robotic-hand.svg";
import Play from "assets/play.svg";
import MagentoLogo from "assets/worksWith/magento.svg";
import ShopifyLogo from "assets/worksWith/shopify-logo.png";
import SquareSpaceLogo from "assets/worksWith/squarespace.svg";
import BigCommerce from "assets/worksWith/bigcommerce-logo.png";
import WooCommerce from "assets/worksWith/woocommerce.svg";
import WixLogo from "assets/worksWith/wix.svg";
import WeeblyLogo from "assets/worksWith/weebly.svg";
import WordPressLogo from "assets/worksWith/wordpress.svg";
import GoogleLogo from "assets/google.svg";
import MicrosoftLogo from "assets/microsoft.svg";
import FacebookLogo from "assets/facebook.svg";
import YahooLogo from "assets/yahoo.svg";
import GoDaddyLogo from "assets/godaddy.svg";
import { Circle } from "components/common";
import { BOOK_DEMO_URL, REGISTER_URL } from "common/constants";
import Marquee from "react-fast-marquee";

export const HomeBanner = () => {
  return (
    <div className="bg-primary-100 pb-40 relative">
      <Container>
        <div className="w-full grid md:grid-cols-2 py-10">
          <div
            className="relative h-screen m-2 max-h-[40vh] md:max-h-[60vh] md:order-2 md:ml-14"
            style={{
              backgroundImage: `url(${Robotichand})`,
              backgroundPosition: "left center, center center",
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundSize: "contain, contain",
            }}
          >
            <Circle classes="bg-gradient-to-r from-primary-300 via-primary-400 to-primary-500 p-4 absolute -bottom-11 md:-bottom-16 left-4 md:left-10 border-2 border-white">
              <img src={Play} width={"50rem"} />
            </Circle>
          </div>

          <div className="md:order-1 mt-10 md:mt-20 lg:mt-0 text-center lg:text-left">
            <div className="title">
              <h1 className="text-6xl font-extrabold">
                Advanced <span className="text-primary-500">AI Agents</span>{" "}
                Trained On Your Own Data
              </h1>
            </div>
            <p className="text-wrapper text-slate-500 mt-2">
              Built for brands who want to offer instant, accurate, and 24/7
              customer support, along with continuous lead generation and
              appointment scheduling, all without the need to hire additional
              agents while enhancing the customer experience.
            </p>
            <div className="flex py-5 justify-center md:justify-start">
              <a
                href={REGISTER_URL}
                className="mr-5 border-2 bg-primary-500 text-white py-2 px-4 rounded-full hover:shadow-md cursor-pointer"
              >
                Get Started
              </a>
              <a
                href={BOOK_DEMO_URL}
                className="border-2 border-primary-500 text-primary-500 py-2 px-4 rounded-full hover:shadow-md cursor-pointer"
              >
                Book a call
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className="container mx-auto bg-white rounded-lg z-10 absolute left-0 right-0 text-center p-10">
        <h3 className="text-xl font-bold">Works with all major platforms</h3>
        <br />
        <Marquee>
          <img src={YahooLogo} width={"120rem"} className="mr-10" alt="yahoo" />
          <img
            src={GoDaddyLogo}
            width={"120rem"}
            className="mr-10"
            alt="godaddy"
          />
          <img
            src={MagentoLogo}
            width={"120rem"}
            className="mr-10"
            alt="mangento"
          />
          <img
            src={ShopifyLogo}
            width={"120rem"}
            className="mr-10"
            alt="shopify"
          />
          <img
            src={BigCommerce}
            width={"120rem"}
            className="mr-10"
            alt="bigcommerce"
          />

          <img
            src={WixLogo}
            width={"80rem"}
            style={{ height: "60px" }}
            className="mr-10"
            alt="wix"
          />

          <img
            src={WooCommerce}
            width={"110rem"}
            style={{ height: "50px" }}
            className="mr-10"
            alt="wocommerce"
          />

          <img
            src={WeeblyLogo}
            width={"120rem"}
            style={{ height: "50px" }}
            className="mr-10"
            alt="weebly"
          />

          <img
            src={WordPressLogo}
            width={"120rem"}
            style={{ height: "50px" }}
            className="mr-10"
            alt="wordpress"
          />
        </Marquee>
      </div>
      <div className="slant slant-bottom"></div>
    </div>
  );
};
