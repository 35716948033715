import { PricingBanner } from "./components";
import ReviewsSection from "sections/ReviewsSection";

function Pricing() {
  return (
    <>
      <PricingBanner />
      <ReviewsSection />
    </>
  );
}
export default Pricing;
