import { useState } from "react";
import { Container } from "components";
import MeteorShower from "assets/meteor-shower.svg";
import { PricingCard } from "./PricingCard";
import { ToggleSwitch } from "flowbite-react";

export const AgencyPricingBanner = () => {
  const [activeInterval, setActiveInterval] = useState("monthly");
  const [currency, setCurrency] = useState("USD");

  return (
    <div
      className="bg-secondary-300"
      style={{
        backgroundImage: `url(${MeteorShower})`,
        backgroundPosition: "left top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <Container>
        <div className="w-full md:w-2/3 mx-auto p-8 md:p-10 text-center">
          <h1 className="text-4xl md:text-6xl font-extrabold">
            Get More Value from your tools with PrimeCX
          </h1>
          <br />
          <p className="text-sm text-slate-500">
            Our pricing plans are designed to be flexible and affordable,
            allowing you to choose the options that best fit your budget and
            needs. With transparent pricing and no hidden fees.
          </p>
        </div>
      </Container>
      <div className="container mx-auto rounded-lg z-10 left-0 right-0">
        <div className="grid gap-4 mx-auto place-content-center mb-10">
          <div className="flex grow">
            <p className="mr-5">Monthly</p>
            <ToggleSwitch
              checked={activeInterval === "yearly"}
              onChange={() => {
                setActiveInterval(
                  activeInterval === "monthly" ? "yearly" : "monthly"
                );
              }}
            />
            <p className="ml-5">Yearly</p>
          </div>
          <div className="text-center">
            <p className="text-primary-500 relative">
              Save Up To 20% &nbsp;
              <svg
                className="absolute right-0 top-5"
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.06239 31.9061C9.64081 31.9735 15.9766 29.4252 20.6765 24.8217C25.3763 20.2183 28.0552 13.9366 28.124 7.35816L33.2922 12.645C33.4995 12.8365 33.7725 12.941 34.0547 12.9367C34.3369 12.9325 34.6067 12.8199 34.8081 12.6222C35.0096 12.4246 35.1274 12.157 35.137 11.875C35.1466 11.5929 35.0474 11.3179 34.86 11.107L27.8387 3.92445C27.7377 3.82108 27.6174 3.73861 27.4846 3.68177C27.3517 3.62493 27.209 3.59483 27.0645 3.59319C26.9201 3.59155 26.7767 3.6184 26.6426 3.67221C26.5085 3.72602 26.3863 3.80573 26.2831 3.90679L19.1005 10.9281C18.9972 11.0291 18.9148 11.1494 18.858 11.2822C18.8012 11.4151 18.7711 11.5578 18.7695 11.7022C18.7679 11.8467 18.7947 11.99 18.8485 12.1241C18.9022 12.2582 18.9819 12.3804 19.0829 12.4837C19.1838 12.587 19.3042 12.6694 19.437 12.7262C19.5698 12.783 19.7125 12.813 19.857 12.8147C20.0015 12.8163 20.1448 12.7895 20.2789 12.7357C20.413 12.682 20.5352 12.6023 20.6385 12.5013L25.9253 7.3332C25.8631 13.3285 23.4222 19.0535 19.1394 23.2493C14.8566 27.4451 9.08263 29.7681 3.08736 29.7073C2.79579 29.704 2.51484 29.8167 2.30633 30.0205C2.09782 30.2243 1.97882 30.5026 1.97551 30.7942C1.9722 31.0858 2.08485 31.3667 2.28868 31.5752C2.49251 31.7837 2.77082 31.9027 3.06239 31.9061Z"
                  fill="#181818"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-center items-center">
        <p className="text-sm mr-5">USD</p>
        <ToggleSwitch
          checked={currency === "INR"}
          onChange={() => {
            setCurrency(currency === "USD" ? "INR" : "USD");
          }}
        />
        <p className="text-sm ml-5">INR</p>
      </div>

      <div className="container mx-auto rounded-lg z-10 left-0 right-0">
        <div className="container items-center justify-center mx-auto p-5 md:p-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 auto-rows-fr ">
            <PricingCard
              activeInterval={activeInterval}
              title="Starter Pack"
              description="Start to engage and build your customer base with these service essentials."
              price={currency === "USD" ? "$199.00" : "₹16699.00"}
              annualPrice={currency === "USD" ? "$1990.00" : "₹166990.00"}
              features={[
                "White Label Dashboard",
                "Custom Knowledge Base",
                "Client Sub-Accounts: Up to 5",
                "5+ Custom A.I. Agents",
                "50k tokens /month A.I. Message Credits",
                "White Label Support Documentation",
                "SaaS Subscriptions & Auto Re-Billing",
              ]}
              ctaBgColor="bg-primary-500"
              ctaTextColor="text-white"
            />
            <PricingCard
              activeInterval={activeInterval}
              title="Agency Plus"
              description="Start to engage and build your customer base with these service essentials."
              price={currency === "USD" ? "$499.00" : "₹41699.00"}
              annualPrice={currency === "USD" ? "$4990.00" : "₹416990.00"}
              features={[
                "Client Sub-Accounts: Up to 30",
                "30+ Custom A.I. Agents",
                "120k tokens /month A.I. Message Credits",
              ]}
              bgColor="bg-gradient-to-r from-primary-300 via-primary-400 to-primary-500"
              textColor="text-white"
            />
            <PricingCard
              activeInterval={activeInterval}
              title="Enterprise"
              description="Start to engage and build your customer base with these service essentials."
              price={currency === "USD" ? "$999.00" : "₹83299.00"}
              annualPrice={currency === "USD" ? "$9990.00" : "₹832990.00"}
              features={[
                "Client Sub-Accounts: Unlimited",
                "Unlimited Custom A.I. Agents",
                "300k tokens /month A.I. Message Credits",
                "A.I. Appointment Scheduler",
                "Conversational Lead Generation",
                "White Label Free Trials",
                "Dedicated VIP Onboarding",
              ]}
              ctaBgColor="bg-primary-500"
              ctaTextColor="text-white"
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <span
            className="text-center underline cursor-pointer text-primary-500 text-sm pb-4"
            onClick={() => {
              window.location.href = "/pricing";
            }}
          >
            View non agency pricing
          </span>
        </div>
      </div>
      {/* <div className="slant slant-bottom"></div> */}
    </div>
  );
};
