import { Card } from "flowbite-react";
import React, { useRef } from "react";
import OmniChannel from "assets/latestResults/omnichannel.svg";
import Optimisation from "assets/latestResults/optimisations.svg";
import Reporting from "assets/latestResults/reporting.svg";
import { BlogComponent } from "components/blog/BlogComponent";

// Define a type for individual review
type Results = {
  icon: string;
  title: string;
  description: string;
};

const reviews: Results[] = [
  {
    description:
      "Monitor Fin’s conversations in the inbox, improve Fin’s answers with suggestions, and create Snippets (short, text-based answers) to continuously elevate Fin’s performance.",
    title: "Optimization",
    icon: Optimisation,
  },
  {
    description:
      "Review Fin’s performance for resolutions, CSAT, and more with pre-built and custom reports.",
    title: "Reporting",
    icon: Reporting,
  },
  {
    description:
      "Fin can effortlessly keep conversations going wherever your customers are—Intercom messenger, WhatsApp, SMS, and more.",
    title: "OmniChannel",
    icon: OmniChannel,
  },
];

const OurLatestResultsSection: React.FC = () => {
  const scrollRef = useRef<HTMLUListElement>(null);

  const scroll = (scrollOffset: number): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="p-10 md:p-20">
      <h1 className="w-full text-left text-3xl font-bold p-4">
        Out latest blogs
      </h1>
      
      <BlogComponent hideTagAndDate={true} />
    </div>
  );
};

export default OurLatestResultsSection;
