import TopBackground from "../assets/faq/faq-background.svg";
import FAQ1 from "../assets/faq/faq-1.svg";
import FAQ2 from "../assets/faq/faq-2.svg";
import { useState } from "react";
import { BlogComponent } from "components/blog/BlogComponent";

type FAQItem = {
  question: string;
  answer: string;
};
type FAQCategory = {
  category: string;
  data: FAQItem[];
};

const faqData: FAQCategory[] = [
  {
    category: "Chatbot Training and Support",
    data: [
      {
        question:
          "Can I add text content to train the chatbot if I don't have web pages to scrape or files to upload?",
        answer: "Yes, you're able to enter raw text content as training data.",
      },
      {
        question:
          "Do you have a plan for agencies to offer chatbots to their clients?",
        answer:
          "Yes, please contact us by sending us an email on support@primecx.ai for more information.",
      },
      {
        question: "Is there a demo that I can try?",
        answer:
          "Yes, you can go to primecx.ai and interact with our AI agent to try out the demo. The demo bot you see on Primecx.ai is trained on primecx.ai website content itself. So you can ask any questions related to the Primecx website in that demo bot and it will answer it. You can also sign up for a 14-day free trial and try out everything on your own data. We also offer one on one demos and you can schedule the same here.",
      },
      {
        question: "What type of content can we use to train the chatbot?",
        answer:
          "You can use any type of content to train the chatbot. The more content you provide, the better the chatbot will be able to answer the questions.",
      },
      {
        question:
          "Do you retrain the chatbot automatically when the website content changes?",
        answer:
          "Right now, for retraining, you have to go to the dashboard and click on the retrain button to retrain the chatbot. We are working on automating this process and do it periodically.",
      },
      {
        question: "Can I upload files to train the chatbot?",
        answer:
          "Yes. You can upload CSV/TXT/PDF/DOCX/XLS files to train the chatbot. The limits vary based on what plan you are on.",
      },
      {
        question: "Some of my files are larger than 10 MB. What do I do?",
        answer:
          "Please contact us on support@primecx.ai. We can figure out a way for you to upload those files.",
      },
      {
        question: "How do I add the chatbot to my website?",
        answer:
          "Each chatbot gets its own unique url, you can embed the chatbot on your own site via the embed code we provide. You can even directly link to the chatbot from your site.",
      },
      {
        question: "How do I train the chatbot?",
        answer:
          "You can train the chatbot by adding a website link, a sitemap link, pdf/word/ direct text etc. You can just enter a URL and the chatbot will be trained on all the content present on that URL. You can also upload CSV/TXT/PDF/DOCX/XLS files to train the chatbots.",
      },
      {
        question: "How long does the training take?",
        answer:
          "It depends on the number of pages you are training. But usually, it should be done within a few minutes.",
      },
      {
        question: "How can we contact you?",
        answer: "You can reach out to us at support@primecx.ai",
      },
      {
        question: "Can the chatbot handle multilingual content?",
        answer:
          "Yes, our chatbot supports multiple languages for training and interactions.",
      },
      {
        question:
          "Is there a limit to the number of questions or responses the chatbot can learn?",
        answer:
          "No, there's no set limit. The chatbot can continuously learn and improve its responses based on the provided content.",
      },
      {
        question:
          "Can I customize the chatbot's responses to suit my brand's tone and style?",
        answer:
          "Absolutely! You have full control over customizing the chatbot's responses to align with your brand's voice and personality.",
      },
      {
        question:
          "What level of accuracy can I expect from the chatbot's responses?",
        answer:
          "Our chatbot strives for high accuracy, but it also depends on the quality and quantity of the training data provided. We continuously work on improving accuracy through updates and enhancements.",
      },
      {
        question:
          "Can I train the chatbot to recognize specific keywords or phrases relevant to my industry or domain?",
        answer:
          "Absolutely! You can train the chatbot with industry-specific terminology and phrases to enhance its understanding and responses within your domain.",
      },
      {
        question:
          "What if I need assistance or have questions while training the chatbot?",
        answer:
          "Our support team is here to help. Feel free to reach out to us at any time via email or through our support portal. We are available on support@primecx.ai or you can also schedule a one on one demo call with us here.",
      },
      {
        question:
          "Do you provide training or resources to help users optimize their chatbot's performance?",
        answer:
          "Yes, we offer training materials, documentation, and tutorials to help users make the most out of their chatbot and optimize its performance. We also offer one on one demo calls and you can schedule the same here.",
      },
      {
        question:
          "What measures do you have in place to ensure data privacy and security?",
        answer:
          "We take data privacy and security seriously. Our platform adheres to industry-standard security protocols and regulations to safeguard your data.",
      },
    ],
  },
  {
    category: "Pricing",
    data: [
      {
        question: "What are your pricing plans and options?",
        answer:
          "We offer flexible pricing plans tailored to your specific needs. Please visit our website or contact us at support@primecx.ai for detailed pricing information.",
      },
      {
        question: "Do you offer a free trial or demo of your pricing plans?",
        answer:
          "Yes, we provide a free trial or demo of our pricing plans. You can sign up on our website to experience our offerings firsthand.",
      },
      {
        question: "Is there a setup fee for getting started with your service?",
        answer:
          "No, as of now we do not charge any setup fees for getting started with our service. There may be a few instances where a complex setup is required or add-on feature development is requested by the customer. In that case a setup fee or a professional services fee can be changed based on the scope of work involved and we will communicate the same in advance.",
      },
      {
        question:
          "Are there any hidden costs or additional charges beyond the listed pricing plans?",
        answer:
          "No, our pricing plans are transparent, and there are no hidden costs or additional charges. You only pay for the plan you choose. However, if you are on a plan that allows you to add your one openai key then in that case you will need to pay separately to openai for the tokens consumed.",
      },
      {
        question: "Can I upgrade or downgrade my plan at any time?",
        answer:
          "Yes, you can easily upgrade or downgrade your plan at any time according to your requirements. Just reach out to us and we will assist you accordingly.",
      },
      {
        question:
          "Do you offer discounts for long-term commitments or bulk purchases?",
        answer:
          "Yes, we offer discounts for long-term commitments or bulk purchases. Please contact us on support@primecx.ai for more information on our discount offerings.",
      },
      {
        question:
          "Are there any volume-based pricing options available for large-scale deployments?",
        answer:
          "Yes, we offer volume-based pricing options for large-scale deployments. Please contact us on support@primecx.ai to discuss your specific needs and get a customized quote.",
      },
      {
        question: "Do you offer custom pricing for enterprise-level solutions?",
        answer:
          "Yes, we provide custom pricing for enterprise-level solutions tailored to your organization's unique requirements. Contact us on support@primecx.ai to discuss your enterprise needs.",
      },
      {
        question: "Is there a minimum contract term for your pricing plans?",
        answer:
          "Our pricing plans are flexible, and there is no minimum contract term. You can choose to pay on a monthly or annual basis.",
      },
      {
        question: "What payment methods do you accept?",
        answer:
          "We accept various payment methods, including credit/debit cards, bank transfers, and online payment gateways.",
      },
      {
        question: "Do you offer refunds or money-back guarantees?",
        answer:
          "No, we do not offer refunds or money back guarantees. Kindly ensure you have tested the solution or connected with us over a call before finalizing. Please refer to our terms and conditions for more details.",
      },
      {
        question: "Can I customize a pricing plan to suit my specific needs?",
        answer:
          "Yes, we offer customizable pricing plans to accommodate your specific requirements. Contact us on support@primecx.ai to discuss your customization needs and get a personalized quote.",
      },
    ],
  },
  {
    category: "Technology and Integrations",
    data: [
      {
        question: "Can you embed a chatbot on multiple websites?",
        answer:
          "Yes, you're able to add any chatbot to any number of sites that you want visitors to interact with.",
      },
      {
        question: "Do you have API for PrimeCX?",
        answer:
          "For API discussion kindly email us on support@primecx.ai with PrimeCX API as subject line.",
      },
      {
        question: "How do I add the chatbot to my website?",
        answer:
          "Each chatbot gets its own unique url, you can embed the chatbot on your own site via the embed code we provide. You can even directly link to the chatbot from your site.",
      },
      {
        question:
          "Can I add PrimeCX to Facebook messenger, Whatsapp, Slack, or any other 3rd party app?",
        answer:
          "As of now we do not support these options, however, please reach out to us on support@primecx.ai and we can work out a custom plan and take up these requests as part of our professional services.",
      },
    ],
  },
];

export function BlogPage() {
  const [activeCategory, setActiveCategory] = useState<string>(
    "Chatbot Training and Support"
  );
  return (
    <div className="bg-white pb-6 p-12 md:px-24">
      <div
        className="flex flex-col-reverse md:flex-row items-center justify-center md:gap-12"
        style={{
          backgroundImage: `url('${TopBackground}')`,
          backgroundSize: "cover", // to cover the entire div
          backgroundPosition: "center", // to center the image
          height: "600px md:400px",
        }}
      >
        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full h-full place-items-center"> */}
          <img src={FAQ1} alt="First" className="w-60 h-96 md:h-64" />
          <div className="">
            <h2 className="text-2xl md:text-5xl font-bold my-4 text-center">
              Our blogs
            </h2>
            <p className="text-center text-gray-400 text-sm">
              From best practices to case studies, the blog section offers a
              wealth of information for businesses looking to improve customer
              engagement and support through chat bots.
            </p>
          </div>

          <img src={FAQ2} alt="Second" className="hidden md:block w-60 h-64" />
        {/* </div> */}
      </div>

      <div className="pt-12">
        <BlogComponent isGrid={true} />
      </div>
    </div>
  );
}

const FAQItemComponent: React.FC<{ faq: FAQItem }> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`p-4 transition-all duration-300 ${
        isOpen ? "shadow-md" : "border border-gray-200 rounded-lg"
      }`}
    >
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-bold">{faq.question}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <div className="mt-2 text-gray-400 text-sm">{faq.answer}</div>}
    </div>
  );
};
