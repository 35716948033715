import { BlogItem } from "type";

const faqData: BlogItem[] = [
  {
    title: "Introduction",
    description: `<pWelcome to PrimeCX, operated by PrimeCX and our team ("PrimeCX", “us”, “we”, or “our”) operates https://primecx.ai/ (hereinafter referred to as “Service”).</p></br>
<p>Our Privacy Policy governs your visit to https://primecx.ai/, and explains how we collect, safeguard, and disclose information that results from your use of our Service.
</p></br>
<p>By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
</p></br>
<p>Our Terms and Conditions (“Terms”) govern all use of our Service and, together with the Privacy Policy, constitutes your agreement with us (“agreement”).</p></br>
`,
  },
  {
    title: "Definitions",
    description: `<p>Service means the https://primecx.ai/ website operated by PrimeCX.</p><br/>
<p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p><br/>
<p>Usage Data is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p><br/>
<p>Cookies are small files stored on your device (computer or mobile device).</p><br/>
<p>Data Controller means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
</p><br/>
<p>Data Processors (or service providers) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p><br/>
<p>Data Subject is any living individual who is the subject of Personal Data.
</p><br/>
<p>The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p><br/>
`,
  },
  {
    title: "Information Collection and Use",
    description: `<p>We collect several different types of information for various purposes to provide and improve our Service to you.</p><br/>`,
  },
  {
    title: "Types of Data Collected",
    description: `<p><strong>Personal Data</strong></p><br/>
<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p><br/>
<p><strong>Email address</strong></p><br/>
<p>First name and last name</p><br/>
<p>Cookies and Usage Data</p><br/>
<p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at support@primecx.ai.</p><br/>
<p><strong>Personal Data</strong></p><br/>
<p>
`,
  },
];
export function PrivacyPolicy() {
  return (
    <div className="bg-white p-14">
      <h5 className="text-2xl md:text-5xl font-bold my-4">Privacy Policy</h5>

      <div className="space-y-4 mt-12 ">
        <div>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              By continuing to use our platform, you agree that you read the
              privacy policy.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Introduction
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Welcome to PrimeCX, operated by PrimeCX and our team ("PrimeCX",
              “us”, “we”, or “our”) operates https://primecx.ai/ (hereinafter
              referred to as “Service”).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Our Privacy Policy governs your visit to https://primecx.ai/, and
              explains how we collect, safeguard, and disclose information that
              results from your use of our Service.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              By using Service, you agree to the collection and use of
              information in accordance with this policy. Unless otherwise
              defined in this Privacy Policy, the terms used in this Privacy
              Policy have the same meanings as in our Terms and Conditions.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Our Terms and Conditions (“Terms”) govern all use of our Service
              and, together with the Privacy Policy, constitutes your agreement
              with us (“agreement”).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Definitions
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Service means the https://primecx.ai/ website operated by PrimeCX.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Usage Data is data collected automatically either generated by the
              use of Service or from Service infrastructure itself (for example,
              the duration of a page visit).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Cookies are small files stored on your device (computer or mobile
              device).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Data Controller means a natural or legal person who (either alone
              or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal data are,
              or are to be, processed. For the purpose of this Privacy Policy,
              we are a Data Controller of your data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Data Processors (or service providers) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Data Subject is any living individual who is the subject of
              Personal Data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              The User is the individual using our Service. The User corresponds
              to the Data Subject, who is the subject of Personal Data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Information Collection and Use
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Types of Data Collected
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Personal Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Email address
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              First name and last name
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Cookies and Usage Data
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials, and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or by emailing at support@primecx.ai.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Personal Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (“Usage Data”). This Usage Data may include
              information such as your computer's Internet Protocol address
              (e.g. IP address), browser type, browser version, the pages of our
              Service that you visit, the time and date of your visit, the time
              spent on those pages, unique device identifiers and other
              diagnostic data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Examples of Cookies we use:
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Session Cookies: We use Session Cookies to operate our Service.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Preference Cookies: We use Preference Cookies to remember your
              preferences and various settings.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Security Cookies: We use Security Cookies for security purposes.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Advertising Cookies: Advertising Cookies are used to serve you
              with advertisements that may be relevant to you and your
              interests.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Use of Data
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              PrimeCX.ai uses the collected data for various purposes:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Personal Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to provide and maintain our Service;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to notify you about changes to our Service;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to allow you to participate in interactive features of our Service
              when you choose to do so;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to provide customer support;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to gather analysis or valuable information so that we can improve
              our Service;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to monitor the usage of our Service;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to detect, prevent and address technical issues;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to fulfill any other purpose for which you provide it;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              in any other way we may describe when you provide the information;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              for any other purpose with your consent.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Retention of Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Transfer of Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country, or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
              If you are located outside United States and choose to provide
              information to us, please note that we may transfer the data,
              including Personal Data, to United States and process it there.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              PrimeCX.ai will take all the steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy, and no transfer of your Personal Data will take
              place to an organisation or a country unless there are adequate
              controls in place including the security of your data and other
              personal information.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Disclosure of Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may disclose personal information that we collect, or you
              provide:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Disclosure for Law Enforcement.
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities. Business Transaction.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              If we or our subsidiaries are involved in a merger, acquisition,
              or asset sale, your Personal Data may be transferred.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Other cases. We may disclose your information also: to fulfill the
              purpose for which you provide it; for the purpose of including
              your company’s logo on our website; for any other purpose with
              your consent.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Security of Data
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              The security of your data is important to us, but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR. – See more at
              https://eur-lex.europa.eu/eli/reg/2016/679/oj
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at support@primecx.ai.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              In certain circumstances, you have the following data protection
              rights:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              the right to access, update, or delete the information we have on
              you; the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete; the right to object. You have the right to object to
              our processing of your Personal Data; the right of restriction.
              You have the right to request that we restrict the processing of
              your personal information; the right to data portability. You have
              the right to be provided with a copy of your Personal Data in a
              structured, machine-readable, and commonly used format;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              the right to withdraw consent. You also have the right to withdraw
              your consent at any time where we rely on your consent to process
              your personal information; Please note that we may ask you to
              verify your identity before responding to such requests. Please
              note that we may not be able to provide Service without some
              necessary data.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA)
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              IalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivable the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy. – See more at:
              https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              According to CalOPPA we agree to the following:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              users can visit our site anonymously; our Privacy Policy link
              includes the word “Privacy”, and can easily be found on the page
              specified above on the home page of our website;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              users are able to change their personal information by emailing us
              at support@primecx.ai.
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Our Policy on “Do Not Track” Signals:&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                According to CalOPPA, we agree to the following:
              </span>
            </strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We honor Do Not Track signals and do not track, plant cookies, or
              use advertising when a Do Not Track browser mechanism is in place.
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Service Providers
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may employ third-party companies and individuals to facilitate
              our Service (“Service Providers”), provide Service on our behalf,
              perform Service-related services, or assist us in analysing how
              our Service is used.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Analytics
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Google Analytics
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page:
              https://policies.google.com/privacy?hl=en
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We also encourage you to review the Google's policy for
              safeguarding your data:
              https://support.google.com/analytics/answer/6004245.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Other Tools
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Tool
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Privacy Policy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Open AI
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://openai.com/policies/privacy-policy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Pinecone
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://www.pinecone.io/privacy/
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Fauna
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              https://fauna.com/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Vercel
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              https://vercel.com/legal/privacy-policy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Cloudflare
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://www.cloudflare.com/privacypolicy/
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Railway
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://railway.app/legal/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              GitHub
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Truto
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              https://truto.one/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Carbon
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://carbon.ai/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              ChartMogul
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://chartmogul.com/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Rewardful
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://www.rewardful.com/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Tolt
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              https://tolt.io/privacy-policy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Scraping Bee
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://www.scrapingbee.com/privacy-policy/
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Logsnag
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://logsnag.com/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              PostHog
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://posthog.com/privacy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Bunny
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              https://bunny.net/privacy/
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              SaaSync
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://www.saasync.com/privacy-policy/
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Featurebase
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              https://www.featurebase.app/privacy-policy
            </span>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Baselime
            </span>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              &nbsp; &nbsp;&nbsp;
            </span>
            <a href="https://baselime.io/privacy">
              <u>
                <span
                  style={{
                    color: "#1155cc",
                    backgroundColor: "#ffffff",
                    fontSize: "11pt",
                  }}
                >
                  https://baselime.io/privacy
                </span>
              </u>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                <br />
                <br />
              </span>
            </a>
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Behavioral Remarketing
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              PrimeCX.ai may use remarketing services to advertise on third
              party websites to you after you visited our Service. We and our
              third-party vendors use cookies to inform, optimise and serve ads
              based on your past visits to our Service.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Google Ads (AdWords)
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Google Ads (AdWords) remarketing service is provided by Google
              Inc.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              You can opt-out of Google Analytics for Display Advertising and
              customize the Google Display Network ads by visiting the Google
              Ads Settings page: http://www.google.com/settings/ads
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Google also recommends installing the Google Analytics Opt-out
              Browser Add-on – https://tools.google.com/dlpage/gaoptout – for
              your web browser. Google Analytics Opt-out Browser Add-on provides
              visitors with the ability to prevent their data from being
              collected and used by Google Analytics. For more information on
              the privacy practices of Google, please visit the Google Privacy
              Terms web page: https://policies.google.com/privacy?hl=en
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page:
              http://www.google.com/settings/ads
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Facebook
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Facebook remarketing service is provided by Facebook Inc.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Facebook remarketing service is provided by Facebook Inc. You can
              learn more about interest-based advertising from Facebook by
              visiting this page: https://www.facebook.com/help/164968693837950
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              To opt-out from Facebook's interest-based ads, follow these
              instructions from Facebook:
              https://www.facebook.com/help/568137493302217
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Facebook adheres to the Self-Regulatory Principles for Online
              Behavioural Advertising established by the Digital Advertising
              Alliance. You can also opt-out from Facebook and other
              participating companies through the Digital Advertising Alliance
              in the USA http://www.aboutads.info/choices/, the Digital
              Advertising Alliance of Canada in Canada http://youradchoices.ca/
              or the European Interactive Digital Advertising Alliance in Europe
              http://www.youronlinechoices.eu/, or opt-out using your mobile
              device settings.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              For more information on the privacy practices of Facebook, please
              visit Facebook's Data Policy:
              https://www.facebook.com/privacy/explanation/
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Payments
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express and Discover. PCI-DSS requirements help ensure
              the secure handling of payment information.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                The payment processors we work with are:
              </span>
            </strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Paddle:&nbsp;
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Paddle is our backup merchant of record. Their privacy policy
                can be viewed at&nbsp;
              </span>
            </strong>
            <a href="https://www.paddle.com/legal/privacy">
              <strong>
                <u>
                  <span
                    style={{
                      color: "#1155cc",
                      backgroundColor: "#ffffff",
                      fontSize: "11pt",
                    }}
                  >
                    https://www.paddle.com/legal/privacy
                  </span>
                </u>
              </strong>
              <strong>
                <span
                  style={{
                    color: "#222222",
                    backgroundColor: "#ffffff",
                    fontSize: "11pt",
                  }}
                >
                  <br />
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: "#222222",
                    backgroundColor: "#ffffff",
                    fontSize: "11pt",
                  }}
                >
                  <br />
                </span>
              </strong>
            </a>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Stripe:&nbsp;
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                <br />
              </span>
            </strong>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Stripe is our merchant of record. Their privacy policy can be
                viewed at&nbsp;
              </span>
            </strong>
            <a href="https://stripe.com/in/privacy">
              <strong>
                <u>
                  <span
                    style={{
                      color: "#1155cc",
                      backgroundColor: "#ffffff",
                      fontSize: "11pt",
                    }}
                  >
                    https://stripe.com/in/privacy
                  </span>
                </u>
              </strong>
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Links to Other Sites
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Children's Privacy
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Our Services are not intended for use by children under the age of
              18 (“Child” or “Children”).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We do not knowingly collect personally identifiable information
              from Children under 18. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Changes to This Privacy Policy
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective, and update
              “effective date” at the top of this Privacy Policy.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              <span
                style={{
                  color: "#222222",
                  backgroundColor: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                Contact Us
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              If you have any questions about this Privacy Policy, please
              contact us by email:&nbsp;
            </span>
            <a href="mailto:support@primecx.ai">
              <u>
                <span
                  style={{
                    color: "#1155cc",
                    backgroundColor: "#ffffff",
                    fontSize: "11pt",
                  }}
                >
                  support@primecx.ai
                </span>
              </u>
            </a>
            <span
              style={{
                color: "#222222",
                backgroundColor: "#ffffff",
                fontSize: "11pt",
              }}
            >
              .
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
