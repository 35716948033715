import { toBold } from "utils/utils";
import TransformCustomerSupportImage from "../../assets/blogs/transfrom-customer-support.png";
import { BlogComponent } from "./BlogComponent";
import { useLocation } from "react-router-dom";
export function TransformCustomerSupport() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const html: string = `
  <p className="c2">
    <span className="c4">
      Is Your Brand Prepared to Transform Customer Support with Chatbots in
      2024?
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      In the dynamic landscape of 2024, businesses are constantly seeking
      innovative ways to enhance customer support and streamline operations. One
      such transformative tool gaining prominence is the AI-driven chatbot.
      However, the question looms: Is your brand ready to leverage the power of
      chatbots?
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">
      Unleashing the Power of Chatbots: Alleviating Agent Burden
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Imagine a scenario where your customer service agents can prioritize
      high-value customer interactions instead of being inundated with
      repetitive queries. The secret lies in incorporating AI-driven chatbots
      strategically. These digital assistants excel at handling routine
      questions, providing customers with swift and accurate responses, and, in
      turn, freeing up valuable human resources.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Yet, this transformation comes with a crucial caveat. Introducing chatbots
      haphazardly, without a thoughtful strategy tailored to your team and
      company, can risk alienating your customers. So, how do you embark on this
      journey without pitfalls?
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">
      Crafting a Seamless Handover: Bot to Human Interaction
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Picture a scenario where a customer seeks more personalized assistance
      beyond the capabilities of a chatbot. Ensuring a smooth transition from
      automated interactions to human support is paramount. A seamless handover,
      where customers can effortlessly request live agent assistance, becomes a
      key element. The rule of thumb is to always have a human agent available,
      preventing customer frustration and enhancing the overall customer
      experience.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">
      Navigating Implementation with Frontline Insights
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      To maximize the benefits of chatbots, your frontline teams need to be
      active participants in the implementation process. Empowering your agents
      and treating them as partners in your business not only fosters a happier
      team but also reduces agent churn. Start by gathering insights from your
      frontline team about the most frequently asked customer questions. Treat
      your agents as the architects of this transformative journey.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">
      Incremental Growth: Starting Small, Measuring Impact, and Scaling
      Effectively
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Implementing a chatbot without a strategic plan is like expecting a
      gourmet meal without proper cooking. To avoid such pitfalls, start small.
      Gauge the impact of the chatbot in a specific area of your customer
      interactions before expanding. By refining the implementation over time,
      your organization can ensure sustainable and effective integration across
      various aspects of customer support.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">
      Setting the Stage for Success: Optimizing Chatbot Functionality
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Success in chatbot implementation lies in strategic optimization aligned
      with business objectives. Create a roadmap that guides the chatbot to
      success, focusing on areas where automation adds significant value. By
      incorporating business logic, your chatbot becomes a powerful tool that
      handles inquiries effectively, ultimately improving customer satisfaction
      and operational efficiency.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">
      Captivating Results: The Immediate Impact of Thoughtful Chatbot
      Integration
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      As you embark on this transformative journey of chatbot integration, the
      immediate impact becomes evident. With chatbots adeptly handling routine
      inquiries, your human agents are liberated to tackle more intricate and
      impactful tasks. The positive changes in customer support are palpable,
      offering a glimpse into the future of streamlined operations.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c4">Embrace the Potential: A Call to Action</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      So, what is your brand waiting for? The transformative capabilities of
      AI-driven chatbots are within reach. Explore how PrimeCX's AI chatbot can
      revolutionize your customer support or reach out for a personal demo. In a
      landscape where customer experience reigns supreme, the strategic
      integration of chatbots can be the game-changer your brand needs. Is your
      brand ready to embrace the potential? The journey begins now.
    </span>
  </p>
`;

  const stringHtml = toBold(html);
  return (
    <div className="bg-white p-14">
      <div className="px-0 md:px-20">
        <h5 className="text-2xl md:text-5xl font-bold my-4">
          Is Your Brand Prepared to Transform Customer Support with Chatbots in
          2024?
        </h5>
        <div className="flex flex-row justify-center">
          <img
            className="w-96 md:w-1/3 h-auto"
            alt=""
            src={TransformCustomerSupportImage}
            style={{
              overflow: "hidden",
              display: "inline-block",
            }}
            title=""
          />
        </div>
        <div
          className="space-y-4 mt-12 "
          dangerouslySetInnerHTML={{ __html: stringHtml }}
        ></div>
      </div>

      <div className="">
        <h1 className="w-full text-left text-3xl font-bold p-4 mt-4">
          Other Posts
        </h1>

        <BlogComponent exclude={[lastPathSegment]} />
      </div>
    </div>
  );
}
