import { Footer as FBFooter } from "flowbite-react";
import PrimeCXLogo from "assets/PrimeCXLogo.png";
import { Circle } from "./Circle";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaCcVisa,
  FaCcMastercard,
  FaApplePay,
  FaGooglePay,
} from "react-icons/fa";
import { COLORS } from "utils";
import { Container } from "components";
import { BOOK_DEMO_URL, REGISTER_URL } from "common/constants";
import { useState } from "react";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const subscribeToNewsletter = async () => {
    if (!email) {
      return;
    }
    setIsLoading(true);
    const formId = "025df3f6-9bae-42b5-9619-0709c853f3c8";
    const portalId = "45797201";

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const formData = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Newsletter subscription successful");
        // Handle success (e.g., showing a success message)
      } else {
        console.error("Newsletter subscription failed");
        // Handle error (e.g., showing an error message)
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      // Handle error (e.g., showing an error message)
    }
    setIsLoading(false);
  };
  return (
    <FBFooter className="text-white relative p-5 mt-40">
      <Container>
        <div className="container mx-auto rounded-lg z-10 relative -mt-20 -top-20 left-0 right-0 bg-orange-100 text-center text-black">
          <div className="lg:w-2/3 mx-auto p-10 text-center">
            <h1 className="text-xl md:text-4xl font-extrabold">
              Advanced AI Agents Trained On Your Own Data
            </h1>
            <br />
            <p className="text-sm text-slate-500">
              Built for brands who want to offer instant, accurate, and 24/7
              customer support, along with continuous lead generation and
              appointment scheduling, all without the need to hire additional
              agents while enhancing the customer experience.
            </p>
            <div className="flex py-5 justify-center flex-col md:flex-row gap-4 sm:justify-left">
              <a
                href={REGISTER_URL}
                className="border-2   bg-primary-500 text-white py-2 px-4 rounded-full hover:shadow-md cursor-pointer"
              >
                Get Started
              </a>
              <a
                href={BOOK_DEMO_URL}
                className="border-2  border-primary-500 text-primary-500 py-2 px-4 rounded-full hover:shadow-md cursor-pointer"
              >
                Book a call
              </a>
            </div>
          </div>
        </div>
        <div className="slant slant-top"></div>
        <div>
          <div className="grid grid-cols-2 gap-4 py-8 md:grid-cols-4">
            <div className="text-left col-span-2 md:col-span-1">
              <FBFooter.Brand href="/" src={PrimeCXLogo} alt="PrimeCX Logo" />
              <p className="my-4 mr-8">
                The only no code AI Agents platform that lets you build chatbots
                for customer support, lead generation, appointment booking and
                internal knowledge base.
              </p>
              <div className="mt-8 flex space-x-6 sm:mt-0">
                <Circle classes="bg-white">
                  <FaFacebookF color={COLORS.PRIMARY} />
                </Circle>
                <Circle classes="bg-white">
                  <FaInstagram color={COLORS.PRIMARY} />
                </Circle>
                <Circle classes="bg-white">
                  <FaYoutube color={COLORS.PRIMARY} />
                </Circle>
              </div>
            </div>
            <div className="mt-4 md:mt-0">
              <FBFooter.Title
                title="Main Menu"
                className="text-2xl font-bold mb-4"
              />
              <FBFooter.LinkGroup col>
                <FBFooter.Link href="/">Home</FBFooter.Link>
                <FBFooter.Link href="/pricing">Pricing</FBFooter.Link>
                <FBFooter.Link href="/features">Features</FBFooter.Link>
                <FBFooter.Link href="/faq">FAQ</FBFooter.Link>
                <FBFooter.Link href="/blog">Blog</FBFooter.Link>
              </FBFooter.LinkGroup>
            </div>
            <div className="mt-4 md:mt-0">
              <FBFooter.Title
                title="Quick Links"
                className="text-2xl font-bold mb-4"
              />
              <FBFooter.LinkGroup col>
                <FBFooter.Link href="/privacy">Privacy Policy</FBFooter.Link>
                <FBFooter.Link href="/refund">Refund Policy</FBFooter.Link>
                <FBFooter.Link href="/terms">
                  Terms &amp; Conditions
                </FBFooter.Link>

                <FBFooter.Link href="https://share.hsforms.com/1rGRKExCqSn22lsQxK6XNKgr9lch">
                  Contact Us
                </FBFooter.Link>
                <FBFooter.Link href="/google-api-disclosure-for-primecx">
                  Google API Disclosure
                </FBFooter.Link>
              </FBFooter.LinkGroup>
            </div>
            <div className="text-left  col-span-2 md:col-span-1 mt-4 md:mt-0">
              <FBFooter.Title
                title="Subscribe to Newsletter"
                className="text-2xl font-bold mb-1"
              />
              <p className="my-4">
                Join our subscribers list to get the lates news and special
                offers.
              </p>
              <div className="md:mt-4 space-x-6 sm:mt-0">
                <div className="flex flex-col items-center justify-center space-y-4">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    className="w-full p-3 text-gray-700 bg-white border border-gray-300 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-500"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="w-full px-4 py-2 text-black bg-white rounded-3xl hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50"
                    onClick={subscribeToNewsletter}
                    disabled={isLoading}
                  >
                    {isLoading ? "Subscribing..." : "Subscribe"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="py-6 sm:flex sm:items-center sm:justify-between">
            <FBFooter.Copyright href="/" by="PrimeCX" year={2024} />
            <div className="text-left flex space-x-2">
              <FaCcVisa />
              <FaCcMastercard />
              <FaApplePay />
              <FaGooglePay />
            </div>
          </div>
        </div>
      </Container>
    </FBFooter>
  );
};
