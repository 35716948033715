import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Layout from "./Layout";
import Home from "./Home";
import Features from "./Features";
import Pricing from "./Pricing";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FaqPage } from "./pages/FaqPage";
import { BlogPage } from "pages/BlogPage";
import { TermsAndConditions } from "pages/TermsAndConditions";
import { PrivacyPolicy } from "pages/PrivacyPolicy";
import { GoogleAPIDisclosure } from "pages/GoogleAPIDisclosure";
import { RefundPolicy } from "pages/RefundPolicy";
import { EnhaceLoyalty } from "components/blog/EnhaceLoyalty";
import { MasteringEmpathy } from "components/blog/MasteringEmpathy";
import { NavigatingCustomerService } from "components/blog/NavigatingCustomerService";
import { ProactiveCustomerService } from "components/blog/ProactiveCustomerService";
import { EssentialInsights } from "components/blog/EssentialInsights";
import { EmbracingEfficiency } from "components/blog/EmbracingEfficiency";
import { ElevatingCustomerExperience } from "components/blog/ElevatingCustomerExperience";
import { ChatGPTImpact } from "components/blog/ChatGPTImpact";
import { CustomerServiceChatbots } from "components/blog/CustomerServiceChatbots";
import { MasteringChatbot } from "components/blog/MasteringChatbot";
import { TransformCustomerSupport } from "components/blog/TransformCustomerSupport";
import { AgencyPricing } from "AgencyPricing";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/enhance-loyalty" element={<EnhaceLoyalty />} />
          <Route
            path="/blog/mastering-empathy"
            element={<MasteringEmpathy />}
          />
          <Route
            path="/blog/navigation-customer-service"
            element={<NavigatingCustomerService />}
          />
          <Route
            path="/blog/proactive-customer-service"
            element={<ProactiveCustomerService />}
          />

          <Route
            path="/blog/essential-insights"
            element={<EssentialInsights />}
          />

          <Route
            path="/blog/embrace-efficiency"
            element={<EmbracingEfficiency />}
          />

          <Route
            path="/blog/elavating-customer-experience"
            element={<ElevatingCustomerExperience />}
          />

          <Route
            path="/blog/customer-service-chatbots"
            element={<CustomerServiceChatbots />}
          />

          <Route
            path="/blog/mastering-chatbot"
            element={<MasteringChatbot />}
          />

          <Route
            path="/blog/transfrom-customer-support"
            element={<TransformCustomerSupport />}
          />

          <Route path="/blog/chatgpt-impact" element={<ChatGPTImpact />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/agency-pricing" element={<AgencyPricing />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/google-api-disclosure-for-primecx" element={<GoogleAPIDisclosure />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
