import { CardWithImageGradient, CommonCard } from "components/common";
import NoCodeImage from "assets/no-code-chatbot.svg";
import TrainedOnWebsiteData from "assets/trained-on-website-data.svg";
import CustomizedChatBot from "assets/builtInFeatures/customized-chatbot.svg";
import GoInstantlyLive from "assets/builtInFeatures/go-instantly-live.svg";
import SetupStartQuestion from "assets/builtInFeatures/setup-starter-question.svg";
import CaptureData from "assets/builtInFeatures/capture-data.svg";
import SupportAllPlatform from "assets/builtInFeatures/support-all-platform.svg";
import AnalyticsOverview from "assets/builtInFeatures/analytics-overview.svg";
import SeamlessHandoff from "assets/builtInFeatures/seamless-handoff.svg";
import React from "react";

export class BuiltInFeaturesSection extends React.Component {
  render() {
    return (
      <div className=" bg-blue-100 relative py-24 px-5 md:py-60  ">
        <div className="slant slant-top"></div>
        <div className="container items-center justify-center mx-auto m-8">
          <h5 className="w-full text-center text-2xl md:text-5xl font-bold p-4">
            Prime CX has incredible built-in Features to power Businesses of all
            Size and Industries
          </h5>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <CommonCard
              title="No Code Chatbot Builder"
              description="Simple easy to use drag & no code chatbot builder that even a 6 year old can use to create a world class customer support chatbot."
              className="min-h-[350px]"
              bgColor="#D3E9FF"
              imageViewConfig={{
                src: NoCodeImage, // URL or path to the image
                rotation: 12, // Rotation in degrees
                marginStart: "10px", // Margin start (left margin)
                margintTop: "-50px",
              }}
              padding={{ bottom: "0px" }}
            />

            <CommonCard
              className="min-h-[350px]"
              title="Trained on your Website Data"
              description="Now you can provide 24x7 support to your customers instantly and accurately. Do you know that a 5-10 min delay in responding to customers can reduce your chance of converting them by 20%? Delayed responses often impact the over all customer Experience. Now you can respond to customers in a FLASH. Yup, just like that!! "
              height="400px md:350px"
              bgColor="#FFFF8F"
              imageViewConfig={{
                src: TrainedOnWebsiteData, // URL or path to the image
                rotation: 12, // Rotation in degrees
                marginStart: "10px", // Margin start (left margin)
              }}
              padding={{ bottom: "0px" }}
            />
          </div>
          <div className="mt-4">
            <CommonCard
              title="Multiple Options to Train Your Chatbot"
              description="An ideal support bot needs to be able to respond to various customer queries instantly and withfully accuracy about your brand, products, policies etc. in order to create a long lasting impact on the customer. You can upload any internal files (Pdf, doc, docx etc), Enter any url, Add your sitemap for uploading your existing FAQ’s. We support multiple options by which you can train your support chat bot. The more training resources you use to train the chatbot the more accurate responses you will get."
              height="550px md:350px"
              bgColor="#FFE096"
              imageViewConfig={{
                src: TrainedOnWebsiteData, // URL or path to the image
                rotation: 12, // Rotation in degrees
                marginStart: "10px", // Margin start (left margin)
              }}
              padding={{ bottom: "0px" }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-10 gap-4 mt-4">
            <div className="md:col-span-6">
              <CommonCard
                shape="square"
                title="Go Live Instantly"
                description="It takes only a few minutes to create, train and add chatbot to your website. We support all platforms."
                height="350px"
                bgColor="#FFFF8F"
                imageViewConfig={{
                  src: GoInstantlyLive,
                  rotation: 12,
                  marginStart: "10px",
                  marginEnd: "-25px",
                  startFromRight: true,
                }}
                padding={{ bottom: "0px" }}
              />
            </div>
            <div className="md:col-span-4">
       
              <CardWithImageGradient
                imageSrc={CustomizedChatBot}
                height="350px"
                title="Customize Your Chatbot"
                description="Fully customize the appearance of the chatbot to match with your branding."
                bgColor="#D3E9FF"
              />
    
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            <CardWithImageGradient
              imageSrc={SetupStartQuestion}
              height="350px"
              title="Set up starter questions"
              description="You can now set up starter questions. This helps customers to simply click on a starter question prompt and get answers instantly.
You can add questions like, About Us, Refund Policy, Privacy policy, Pricing etc."
              bgColor="#FFE096"
            />
            <CardWithImageGradient
              imageSrc={CaptureData}
              height="350px"
              title="Capture Visitor Data"
              description="You can now set up form fields to capture user data.This will help you keep track of all your chats with that person.You will have the flexibility enable or disable collecting information as per your company policy."
              bgColor="#D3E9FF"
            />
            <CardWithImageGradient
              imageSrc={SupportAllPlatform}
              height="350px"
              title="Support All Platforms"
              description="No matter what platform you use for your site. We support all platforms and custom codes. As long as you have a website, you can use our solution to create a support chatbot for your business."
              bgColor="#FFE096"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <CommonCard
              title="Seamless Agent handoff"
              description="When the AI Agent is unable to answer a question, it gives an option to seamlessly hand over the conversation to live agents to ensure no queries are getting missed and you are able to capture all customers and help them easily. This ensures your customer always gets a response."
              height="400px md:350px"
              bgColor="#FFFF8F"
              imageViewConfig={{
                src: SeamlessHandoff, // URL or path to the image
                rotation: 12, // Rotation in degrees
                marginStart: "10px", // Margin start (left margin)
                marginEnd: "-25px",
                startFromRight: true,
              }}
              padding={{ bottom: "0px" }}
            />

            <CommonCard
              title="Analytics Overview"
              description="Understand why customers are contacting you.
Our analytics will help you get an overview of the support function and get you important stats."
              height="350px"
              bgColor="#D3E9FF"
              imageViewConfig={{
                src: AnalyticsOverview,
                rotation: 12, // Rotation in degrees
                marginStart: "10px", // Margin start (left margin)
                margintTop: "-50px",
                marginEnd: "-25px",
                startFromRight: true,
              }}
              padding={{ bottom: "0px" }}
            />
          </div>
        </div>
        <div className="slant slant-bottom"></div>
      </div>
    );
  }
}
