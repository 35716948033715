export const SupportVolumnSection: React.FC = () => {
  return (
    <div className="p-10" style={{ backgroundColor: "#FEEAE3" }}>
      <div className="flex flex-wrap items-center justify-center mx-auto ">
        <h5
          className="text-center text-3xl font-bold px-4 py-2"
          style={{ width: "550px" }}
        >
          Experience a major reduction in support volume
        </h5>
        <h1 className="w-full text-center text-sm px-4 py-1">
          Unleash the Potential of PrimeCX Across a Spectrum of Industries and
          Applications.
        </h1>
        <div className="p-5 md:p-15 container grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          <ItemCard percentage="65%" title="Boost in CSAT" />
          <ItemCard percentage="75%" title="Decrease in Costs" />
          <ItemCard percentage="100%" title="Increase in ESAT" />
          <ItemCard percentage="80%" title="Queries Self-Served" />
        </div>
      </div>
    </div>
  );
};

function ItemCard({
  percentage,
  title,
}: {
  percentage: string;
  title: string;
}) {
  return (
    <div className="w-full items-left bg-white rounded-lg p-2 md:p-4 shadow-md h-28 justify-center">
      <div className="text-transparent bg-clip-text bg-gradient-to-r from-primary-300 via-primary-500 to-red-900 font-extrabold text-lg md:text-3xl font">
        {percentage}
      </div>
      <p className="w-full text-xs md:text-sm py-1">{title}</p>
    </div>
  );
}
