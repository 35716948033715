import { toBold } from "utils/utils";
import EmbracingEfficiencyIcon from "../../assets/blogs/embrace-efficiency.png";
import { BlogComponent } from "./BlogComponent";
import { useLocation } from "react-router-dom";
export function EmbracingEfficiency() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const html: string = `
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      In the evolving landscape of customer service, Artificial Intelligence
      (AI) has transcended its buzzword status to become a transformative force.
      Leading this revolution is ChatGPT, disrupting industries and
      democratizing advanced capabilities once reserved for tech-savvy
      professionals.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Amidst the excitement, concerns linger about AI, particularly its
      perceived threat to customer service jobs. However, a closer examination
      reveals a symbiotic relationship where AI, represented by ChatGPT,
      enhances rather than replaces human agents. Instead of job displacement,
      AI empowers customer service teams, automating mundane tasks and freeing
      up valuable time for more meaningful endeavors.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">**1. Better &amp; More Meaningful Work**</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      AI, particularly chatbots, excels at handling repetitive, manual tasks,
      liberating customer service teams from the drudgery of data entry. This
      shift allows human agents to focus on tasks that demand a human touch,
      fostering engagement and job satisfaction. A Gallup report emphasizes the
      importance of providing opportunities for meaningful work to enhance
      workforce motivation and purpose, directly countering the tradition of low
      engagement levels in customer service.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      By integrating chatbots into the service channel mix, organizations
      elevate the role of human agents. AI tools assist agents in understanding
      customer issues, drafting responses, and facilitating self-service,
      contributing to a more engaging and valuable work environment.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">**2. Improved Working Conditions**</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Customer service, driven by empathy, often entails challenging
      interactions with dissatisfied customers. Managing stress and maintaining
      empathy are critical for the well-being of service representatives. Here,
      chatbots act as gatekeepers, handling incoming requests and alleviating
      the overall workload. This proactive support helps reduce stress levels,
      ensuring that agents can sustain their empathetic approach even in
      demanding situations.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Beyond stress management, chatbots offer a solution to the tough working
      conditions typical in customer service, including shift limitations,
      weekend work, and vacation blackouts. Available 24/7, chatbots mitigate
      the need for night shifts and weekend work, contributing to a healthier
      work-life balance for customer service teams. This strategic use of AI
      supports long-term employee retention, positively impacting onboarding,
      training costs, and overall customer satisfaction.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">**3. Automation Boosts Satisfaction**</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Contrary to fears of job dissatisfaction, automation, including chatbots,
      has proven to enhance overall employee satisfaction. Companies
      implementing automation report a notable increase in staff happiness by
      92%. This stems from the inherent desire of employees to feel that their
      time and work contribute value. Automation aligns with this aspiration,
      allowing employees to focus on meaningful tasks that contribute to the
      business.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      From an employer's perspective, AI chatbots present an opportunity to not
      only streamline costs but also boost employee satisfaction on the service
      floor. This positive impact on staff happiness translates to a more
      stable, satisfied workforce, essential for maintaining service quality,
      customer satisfaction, and long-term business success.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      In essence, rather than posing a threat, chatbots emerge as allies,
      enabling customer service teams to excel in their roles. The harmonious
      integration of AI technologies empowers agents, fosters job satisfaction,
      and enhances overall efficiency. As organizations navigate this
      transformative era, the strategic embrace of AI, guided by insights from
      our eBook "Beginner’s Guide to Customer Service Chatbots," becomes a key
      driver for sustained success.
    </span>
  </p>

`;

  const stringHtml = toBold(html);
  return (
    <div className="bg-white p-14">
      <div className="px-0 md:px-20">
        <h5 className="text-2xl md:text-5xl font-bold my-4">
          Embracing Efficiency: 3 Compelling Reasons Your Customer Service Team
          Will Embrace Chatbots
        </h5>
        <div className="flex flex-row justify-center">
          <img
            className="w-96 md:w-1/3 h-auto"
            alt=""
            src={EmbracingEfficiencyIcon}
            style={{
              overflow: "hidden",
              display: "inline-block",
            }}
            title=""
          />
        </div>
        <div
          className="space-y-4 mt-12 "
          dangerouslySetInnerHTML={{ __html: stringHtml }}
        ></div>
      </div>

      <div className="">
        <h1 className="w-full text-left text-3xl font-bold p-4 mt-4">
          Other Posts
        </h1>

        <BlogComponent exclude={[lastPathSegment]} />
      </div>
    </div>
  );
}
