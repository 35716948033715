import "./App.css";
import "./assets/style.css";
import { Header, Footer } from "./components";
import { Outlet } from "react-router-dom";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from "flowbite-react";
function Layout() {
  const customTheme: CustomFlowbiteTheme = {
    button: {
      color: {
        primary: "bg-primary-500 text-white hover:shadow-md",
        secondary: "bg-transparent text-primary-500 outline hover:shadow-md",
      },
    },
    footer: {
      root: {
        base: "bg-primary-500 text-white",
      },
      title: {
        base: "text-white",
      },
      groupLink: {
        base: "text-white",
      },
      copyright: {
        base: "text-white",
      },
    },
  };
  return (
    <Flowbite theme={{ theme: customTheme }}>
      <Header />
      <Outlet />
      <Footer />
    </Flowbite>
  );
}
export default Layout;
