import { Container } from "components";
import MeteorShower from "assets/meteor-shower.svg";
import MainBanner from "assets/features-banner-image.svg";
import { BOOK_DEMO_URL, REGISTER_URL } from "common/constants";

export const FeaturesBanner = () => {
  return (
    <>
    <div
      className="bg-secondary-300 relative"
      style={{
        backgroundImage: `url(${MeteorShower})`,
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <Container>
        <div className="md:w-2/3 mx-auto p-5 md:p-10 text-center">
          <h3 className="text-xl font-bold text-primary-500">Features</h3>
          <br />
          <h1 className="text-4xl md:text-6xl font-extrabold">
            The AI chatbot that's an expert on your products
          </h1>
          <br />
          <p>
            It's like having ChatGPT specifically for your products. Instantly
            answer your visitors' questions with a personalized chatbot trained
            on your website content.
          </p>
          <div className="flex py-5 justify-center sm:justify-left">
            <a
              href={REGISTER_URL}
              className="mr-5 border-2 bg-primary-500 text-white py-2 px-4 rounded-full hover:shadow-md cursor-pointer"
            >
              Get Started
            </a>
            <a
              href={BOOK_DEMO_URL}
              className="border-2 border-primary-500 text-primary-500 py-2 px-4 rounded-full hover:shadow-md cursor-pointer"
            >
              Book a call
            </a>
          </div>
        </div>
      </Container>
      {/* <div className="slant slant-bottom"></div> */}
      <div className="container mx-auto rounded-lg z-10 left-0 right-0">
        <img src={MainBanner} />
        
      </div>
     
    </div>
    
      </>
  );
};
