export interface ContainerProps {
  children: React.ReactNode;
}
export const Container = ({ children }: ContainerProps) => {
  return (
    <div className="mx-auto flex flex-wrap items-center container justify-center p-5 md:p-8">
      {children}
    </div>
  );
};
