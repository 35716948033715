import { toBold } from "utils/utils";
import CustomerServiceChatbotsImage from "../../assets/blogs/customer-service-chatbots.png";
import { BlogComponent } from "./BlogComponent";
import { useLocation } from "react-router-dom";
export function CustomerServiceChatbots() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const html: string = `
  <p className="c2">
    <span className="c1">
      In the ever-evolving landscape of customer service, eCommerce companies
      are turning to chatbots as invaluable allies. These virtual assistants
      promise to tackle the myriad challenges faced by customer service teams,
      especially in a domain where 80% of queries can be automated. Dive into
      the world of customer service chatbots with our guide, exploring the dos
      and don’ts tailored for eCommerce success.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **Unveiling the Potential of Customer Service Chatbots**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Customer service chatbots offer a unique solution, particularly for
      eCommerce entities grappling with a barrage of customer queries. The
      allure lies in their ability to operate 24/7, addressing repetitive
      inquiries and sifting through low-value questions, allowing human agents
      to focus on more complex interactions.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **Navigating Chatbot Capabilities: What They Can Solve**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      1. **Repetitive Questions:** Delegate frequently asked questions such as
      shipping costs and order tracking to your chatbot, freeing up valuable
      human resources for more intricate tasks.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      2. **Simple Queries:** For inquiries with standard answers or easily
      retrievable information, harness the efficiency of chatbots. Let them
      handle routine tasks, leaving your service team to delve into more nuanced
      customer interactions.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      3. **Empathy-Neutral Questions:** While bots excel at handling high
      volumes of routine queries, they lack the emotional touch. Reserve
      empathy-driven and judgment-based scenarios for your human service team.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      4. **Troubleshooting:** Streamline issue resolution by deploying chatbots
      to diagnose problems through a series of targeted questions, especially
      beneficial in industries like hardware where systematic troubleshooting is
      prevalent.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      5. **Information-Gathering for Support Agents:** Utilize chatbots to
      collect preliminary information in complex cases before seamlessly
      transitioning the interaction to a human service agent. This ensures a
      smooth collaboration between bots and humans.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      **Mastering the Art of Customer Service Chatbots: Dos &amp; Don’ts**
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">*Dos:*</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      1. **Visual Enhancements:** Elevate your chatbot responses with visuals
      like gifs and images. Not only do they add personality, but they also
      enhance communication, especially when explaining processes or providing
      directions.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      2. **Defined Tone and Personality:** Infuse your chatbot with a consistent
      tone and personality aligned with your brand. Whether formal or casual,
      maintain coherence to build a cohesive brand image.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      3. **Clarity on Bot Identity:** Transparency is key. Clearly communicate
      that users are interacting with a chatbot, avoiding any attempt to pass
      off automation as human interaction.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      4. **Regular Maintenance:** Treat your chatbot as an evolving entity.
      Regularly update and maintain content to ensure accuracy and relevance,
      preventing it from becoming an outdated resource.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      5. **Priority Status:** Allocate dedicated resources within your service
      team to oversee chatbot operations. While initial setup may require
      effort, ongoing maintenance is generally low, making it a worthwhile
      investment.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">*Don’ts:*</span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      1. **FAQ Misconception:** Avoid transforming your chatbot into an extended
      FAQ. Keep responses concise, specific, and tailored to the user's query,
      linking to detailed FAQs if necessary.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      2. **Impersonal Lengthy Texts:** Steer clear of presenting users with
      extensive blocks of text. Ensure your chatbot delivers short, precise
      answers to maintain user engagement.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      3. **Human Impersonation:** Resist the temptation to mislead users into
      believing they are interacting with a human. Clearly state that it's a
      chatbot, and seamlessly transition to human assistance if required.
    </span>
  </p>
  <p className="c0">
    <span className="c1" />
  </p>
  <p className="c2">
    <span className="c1">
      Embrace the potential of customer service chatbots in your eCommerce
      journey, providing users with a seamless and efficient experience. For
      those venturing into chatbot implementation, our comprehensive guide can
      serve as a valuable starting point. Elevate your customer service game
      with the right blend of automation and human touch.
    </span>
  </p>
`;

  const stringHtml = toBold(html);
  return (
    <div className="bg-white p-14">
      <div className="px-10 md:px-20">
        <h5 className="text-2xl md:text-5xl font-bold my-4">
          Customer service chatbots: Dos & don’ts for eCommerce companies
        </h5>

        <div className="flex flex-row justify-center">
          <img
            alt=""
            src={CustomerServiceChatbotsImage}
            style={{
              width: "300px md:600px",
              height: "300px md:600px",
            }}
            title=""
          />
        </div>
        <div
          className="space-y-4 mt-12 "
          dangerouslySetInnerHTML={{ __html: stringHtml }}
        ></div>
      </div>
      <div className="">
        <h1 className="w-full text-left text-3xl font-bold p-4 mt-4">
          Other Posts
        </h1>

        <BlogComponent exclude={[lastPathSegment]} />
      </div>
    </div>
  );
}
