import React, { useRef } from "react";
import EnhaceLoyalty from "../../assets/blogs/enhance-loyalty.png";
import MasteringEmpathyImage from "../../assets/blogs/mastering-empathy.png";
import NavigatingCustomerServiceImage from "../../assets/blogs/navigating-customer-service.png";
import EssentialInsightsIcon from "../../assets/blogs/essential-insights.jpg";
import EmbracingEfficiencyIcon from "../../assets/blogs/embrace-efficiency.png";
import ElevatingCustomerExperienceIcon from "../../assets/blogs/elavating-customer-experience.png";
import ChatGPTImpact from "../../assets/blogs/chatgpt-impact2.jpg";
import CustomerServiceChatbotsImage from "../../assets/blogs/customer-service-chatbots.png";
import MasteringChatbot from "../../assets/blogs/mastering-chatbot.jpg";
import TransformCustomerSupportImage from "../../assets/blogs/transfrom-customer-support.png";

// Define a type for individual review
type Results = {
  icon: string;
  title: string;
  description: string;
  tag?: string; // Optional tag for each result
  date?: string;
  href?: string;
  id: string;
};

const blogs: Results[] = [
  {
    description:
      "Monitor Fin’s conversations in the inbox, improve Fin’s answers with suggestions, and create Snippets (short, text-based answers) to continuously elevate Fin’s performance.",
    title:
      "Enhancing Customer Loyalty: 5 Practical Approaches to Boost Retention with AI",
    icon: EnhaceLoyalty,
    tag: "New",
    date: "Mar 30, 2024",
    href: "/blog/enhance-loyalty",
    id: "enhance-loyalty",
  },
  {
    description:
      "In the dynamic landscape of business, exceptional customer service has evolved from a luxury to a necessity, becoming the pivotal factor that can define success or failure. This transformation hinges on a singular, yet crucial trait – empathy.",
    title:
      "Mastering Empathy in Customer Service: 10 Genuine Statements for Lasting Connections",
    icon: MasteringEmpathyImage,
    tag: "Updated",
    date: "Apr 07, 2024",
    href: "/blog/mastering-empathy",
    id: "mastering-empathy",
  },
  {
    description:
      "In the realm of business metrics, where everything is meticulously measured, customer service team performance stands out as a critical factor that directly influences revenue and brand loyalty. Statistics reveal that 83% of executives acknowledge the risk bad customer experience poses to revenue, while 73% of consumers emphasize the pivotal role of a positive experience in shaping brand loyalty.",
    title:
      "Navigating Customer Service Performance Evaluation: Avoiding 3 Common Pitfalls",
    icon: NavigatingCustomerServiceImage,
    tag: "Featured",
    date: "Apr 15, 2024",
    href: "/blog/navigation-customer-service",
    id: "navigation-customer-service",
  },
  {
    description:
      "In the fast-paced landscape of customer service, where expectations continue to evolve, the shift towards proactive customer service is more crucial than ever. While resolving issues promptly remains essential, the art of anticipating customer needs and addressing them before problems arise is a game-changer. Let's explore the key strategies and benefits of proactive customer service that go beyond mere issue resolution.",
    title:
      "The Art of Proactive Customer Service: Building Relationships Beyond Resolutions",
    icon: EnhaceLoyalty,
    tag: "Featured",
    date: "May 01, 2024",
    href: "/blog/proactive-customer-service",
    id: "proactive-customer-service",
  },
  {
    description:
      "As the importance of delivering an exceptional customer experience continues to rise, businesses are placing strategic emphasis on customer service. A recent survey by McKinsey & Company highlighted the increasing recognition of customer service as a crucial focus for companies. However, aligning organizational priorities with customer expectations can be a nuanced challenge.",
    title:
      "Essential Insights: 10 Key Metrics for Evaluating Customer Service Agent Performance",
    icon: EssentialInsightsIcon,
    tag: "Featured",
    date: "May 07, 2024",
    href: "/blog/essential-insights",
    id: "essential-insights",
  },
  {
    description:
      "In the evolving landscape of customer service, Artificial Intelligence (AI) has transcended its buzzword status to become a transformative force. Leading this revolution is ChatGPT, disrupting industries and democratizing advanced capabilities once reserved for tech-savvy professionals.",
    title:
      "Embracing Efficiency: 3 Compelling Reasons Your Customer Service Team Will Embrace Chatbots",
    icon: EmbracingEfficiencyIcon,
    tag: "Featured",
    date: "May 16, 2024",
    href: "/blog/embrace-efficiency",
    id: "embrace-efficiency",
  },
  {
    description:
      "AI is rapidly reshaping the customer service landscape, with innovations like Open AI’s ChatGPT revolutionizing how businesses deliver personalized and efficient support. By harnessing machine learning algorithms, natural language processing, and other AI tools, customer service teams can now enhance speed, accuracy, and personalization, leading to cost reductions and heightened customer satisfaction.",
    title:
      "Elevating Customer Experience: Unleashing the Power of AI in Customer Service",
    icon: ElevatingCustomerExperienceIcon,
    tag: "Featured",
    date: "May 31, 2024",
    href: "/blog/elavating-customer-experience",
    id: "elavating-customer-experience",
  },
  {
    description:
      "In recent times, the buzz around ChatGPT has been hard to ignore. This unassuming chatbot has skyrocketed to 100 million active users in just two months, outpacing the growth of social media giants like TikTok and Instagram. While ChatGPT is making waves in various industries, the question lingers: Is it poised to snatch away our customer service jobs?",
    title: "Navigating the Impact of ChatGPT on Customer Service Roles",
    icon: ChatGPTImpact,
    tag: "Featured",
    date: "Jun 07, 2024",
    href: "/blog/chatgpt-impact",
    id: "chatgpt-impact",
  },
  {
    description:
      "In the ever-evolving landscape of customer service, eCommerce companies are turning to chatbots as invaluable allies. These virtual assistants promise to tackle the myriad challenges faced by customer service teams, especially in a domain where 80% of queries can be automated. Dive into the world of customer service chatbots with our guide, exploring the dos and don’ts tailored for eCommerce success.",
    title: "Customer service chatbots: Dos & don’ts for eCommerce companies",
    icon: CustomerServiceChatbotsImage,
    tag: "Featured",
    date: "Jun 15, 2024",
    href: "/blog/customer-service-chatbots",
    id: "customer-service-chatbots",
  },
  {
    description:
      "In the fiercely competitive business landscape of 2024, companies are undergoing digital transformation to stay ahead. The integration of various digital tools is becoming a cornerstone for growth, and among these tools, chatbots are taking center stage.",
    title: "Mastering Chatbot White Labeling in 2024: A Comprehensive Handbook",
    icon: MasteringChatbot,
    tag: "Featured",
    date: "Jun 30, 2024",
    href: "/blog/mastering-chatbot",
    id: "mastering-chatbot",
  },
  {
    description:
      "In the dynamic landscape of 2024, businesses are constantly seeking innovative ways to enhance customer support and streamline operations. One such transformative tool gaining prominence is the AI-driven chatbot. However, the question looms: Is your brand ready to leverage the power of chatbots?",
    title:
      "Is Your Brand Prepared to Transform Customer Support with Chatbots in 2024?",
    icon: TransformCustomerSupportImage,
    tag: "Featured",
    date: "July 16, 2024",
    href: "/blog/transfrom-customer-support",
    id: "transfrom-customer-support",
  },
];

interface BlogComponentProps {
  isGrid?: boolean;
  hideTagAndDate?: boolean;
  exclude?: string[];
}

export const BlogComponent: React.FC<BlogComponentProps> = ({
  isGrid = false,
  hideTagAndDate = false,
  exclude = [],
}) => {
  const scrollRef = useRef<HTMLUListElement>(null);

  const containerClasses = isGrid
    ? "grid grid-cols-1 md:grid-cols-3  gap-4 p-1 grid-cols-1 md:grid-cols-[repeat(auto-fill,_18rem)]"
    : "flex overflow-x-scroll no-scrollbar p-1  gap-4";

    const cardWith = isGrid ? 'w-full' : 'w-72'; 
  const filteredBlogs = blogs.filter((blog) => !exclude.includes(blog.id));

  return (
    <div className="">
      <ul ref={scrollRef} className={containerClasses}>
        {filteredBlogs.map((result, index) => (
          <li key={index} className="">
            <div
              className={`${'flex flex-col justify-between max-w-sm p-3 bg-white border border-gray-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  md:max-w-72 min-h-80'} ${cardWith}` }
            >
              <img
                src={result.icon}
                alt="quote"
                className="mb-2 h-32 w-full object-cover object-center rounded-lg"
              />
              {!hideTagAndDate && (
                <div className="flex justify-between items-center mb-2">
                  <span className="bg-[#FFFF8F] text-xs font-bold py-1 px-2 rounded">
                    {result.tag}
                  </span>
                  <span className="text-xs">{result.date}</span>
                </div>
              )}
              <div>
                <p
                  className="text-gray-900 text-sm font-bold overflow-hidden"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {result.title}
                </p>
                <p
                  className="text-gray-400 text-xs font-bold overflow-hidden mt-2"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {result.description}
                </p>
                {result.href && (
                  <a href={result.href} className="text-blue-500 text-xs mt-2">
                    Read more
                  </a>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
