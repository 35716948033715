import { CommonCard } from "components/common";
import CustomerSupport from "assets/useCases/customer-support.svg";
import LeadGeneration from "assets/useCases/lead-generation.svg";
import KnowledgeBase from "assets/useCases/knowledge-base.svg";
import DoubleChecked from "assets/double-checked.svg";
import AppointmentSetting from "assets/useCases/appointment-setting.svg";

import React from "react";

export class UnlimitedUseCasesSection extends React.Component {
  render() {
    return (
      <div className="py-20 relative">
        <div className="flex flex-wrap items-center justify-center mx-auto p-8">
          <h5 className="w-full text-center text-5xl font-bold px-4 py-2">
            Unlimited Use Cases
          </h5>
          <h1 className="w-full text-center text-lg px-4 py-2">
            Unleash the Potential of PrimeCX Across a Spectrum of Industries and
            Applications.
          </h1>
          <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <CommonCard
              shape="none"
              imageSrc={CustomerSupport}
              title="Customer Support"
              height="350px md:280px"
              bgColor="#f3f4f6"
            >
              <div className="p-2">
                <FeatureItem text="Can Handle 1000+ chats simultaneously" />
                <FeatureItem text="24/7 customer service with immediate responses" />
                <FeatureItem text="Saves time for human agents to tackle complex issues." />
                <FeatureItem text="Personalized Human-like conversations" />
              </div>
            </CommonCard>

            <CommonCard
              shape="none"
              imageSrc={LeadGeneration}
              title="Lead Generation"
              height="350px md:280px"
              bgColor="#f3f4f6"
            >
              <div className="p-2">
                <FeatureItem text="Capture customer details on the go" />
                <FeatureItem text="Provide instant responses and resolutions" />
                <FeatureItem text="Never miss a potential prospect" />
                <FeatureItem text="Fillup your teams pipeline with qualified leads" />
              </div>
            </CommonCard>

            <CommonCard
              shape="none"
              imageSrc={AppointmentSetting}
              title="Appointment Setting"
              height="350px md:280px"
              bgColor="#f3f4f6"
            >
              <div className="p-2">
                <FeatureItem text="Automated lead engagement" />
                <FeatureItem text="AI meeting scheduler" />
                <FeatureItem text="Boost your Connect rate by 40%" />
                <FeatureItem text="Get 30-50% more sales-ready meetings" />
              </div>
            </CommonCard>

            <CommonCard
              shape="none"
              imageSrc={KnowledgeBase}
              title="Internal Knowledge Base"
              height="350px md:280px"
              bgColor="#f3f4f6"
            >
              <div className="p-2">
                <FeatureItem text="Enable your team to find solutions faster" />
                <FeatureItem text="Engage with internal team" />
                <FeatureItem text="AI copilot for knowledge sharing" />
                <FeatureItem text="Help respond to customers faster" />
              </div>
            </CommonCard>
          </div>
        </div>
      </div>
    );
  }
}

const FeatureItem: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="flex items-center space-x-2">
      <img src={DoubleChecked} alt="icon" />
      <span className="text-gray-600">{text}</span>
    </div>
  );
};
