export function GoogleAPIDisclosure() {
    return (
        <div className="bg-white p-14">
          <h5 className="text-2xl md:text-5xl font-bold my-4">
            Google API Disclosure
          </h5>
    
          <div className="space-y-4 mt-12 ">
            <p>PrimeCX apps use Google APIs when you use your Google account to sign in to PrimeCX apps.</p>
            <p>PrimeCX app’s use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank"><span style={{color: "#1155cc",backgroundColor: "#ffffff"}}>Google API Services User Data Policy</span></a>, including the Limited Use requirements.</p>
            <p>You can also see the <a href="/privacy"><span style={{color: "#1155cc",backgroundColor: "#ffffff"}}>PrimeCX Privacy Policy</span></a>. If you have any questions, clarifications, or concerns, please contact us at 
                <a className="pl-2" href="mailto:support@primecx.ai">
                    <u>
                        <span
                        style={{
                            color: "#1155cc",
                            backgroundColor: "#ffffff",
                            fontSize: "11pt",
                        }}
                        >
                        support@primecx.ai
                        </span>
                    </u>
                </a>
            </p>
          </div>
        </div>
      );
}